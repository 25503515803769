import mas from '../../assets/imgs/mas.png';

const BtnMas = () => {
    return (
        <div className="btn-aux">
            <div className="d-flex flex-column align-items-center">
                <img src={mas} alt="Ver detalle" />
            </div>
        </div>
    );
};

export default BtnMas;