import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import BtnMas from "../../molecules/btnMas";
import Countdown from "react-countdown";

const AutotestWalkInCarrascoParqueRodo = () => {
  const linkMP = "https://mpago.la/2gZaapp";
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showSubmitOk, setShowSubmitOk] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const formInitialValues = {
    apellido: "",
    nombres: "",
    tipo_doc: "",
    nro_doc: "",
    telefono: "",
    email: "",
    tuvo_covid: "",
    fecha_diagnostico: "",
    lugar_solicitado: "",
    fecha_solicitada: "",
    forma_ingreso: "",
    nro_pasaje_bqb: "",
    comentarios: "",
  };

  const formValidationSchema = yup.object().shape({
    apellido: yup.string().required("Debe ingresar sus apellidos."),
    nombres: yup.string().required("Debe ingresar sus nombres."),
    tipo_doc: yup.string().required("Debe seleccionar el tipo de documento."),
    nro_doc: yup.string().required("Debe ingresar el número de documento."),
    telefono: yup.string().required("Debe ingresar su teléfono o celular."),
    email: yup
      .string()
      .email("Debe ingresar un email válido.")
      .required("Debe ingresar su email."),
    tuvo_covid: yup.string().required("Debe seleccionar una opción."),
    fecha_diagnostico: yup.string().when("tuvo_covid", (tuvo_covid) => {
      if (tuvo_covid && tuvo_covid.toLowerCase() === "si") {
        return yup
          .string()
          .required("Debe ingresar la fecha de su diagnóstico.");
      }
    }),
    lugar_solicitado: yup
      .string()
      .required(
        "Debe seleccionar el lugar solicitado para realizar el test."
      ),
    fecha_solicitada: yup
      .date()
      .required("Debe ingresar la fecha en la que desea realizar su test.")
      .when("lugar_solicitado", (lugar_solicitado, schema) => {
        if (
          !!lugar_solicitado &&
          lugar_solicitado !== "Autotest Montevideo LATU"
        ) {
          return schema.test({
            test: (fecha_solicitada) => {
              return moment(fecha_solicitada).day() !== 0;
            },
            message: "Seleccione un día que no sea domingo.",
          });
        }
      }),
    forma_ingreso: yup
      .string()
      .required("Debe seleccionar la forma de ingreso al país."),
    nro_pasaje_bqb: yup.string().when("forma_ingreso", (forma_ingreso) => {
      if (forma_ingreso && forma_ingreso.toLowerCase() === "buquebus") {
        return yup
          .string()
          .required("Debe ingresar el número de pasaje de Buquebus.");
      }
    }),
    comentarios: yup.string(),
  });

  const formSubmit = async (values, { setSubmitting, resetForm }) => {
    setShowSubmitError(false);
    setShowSubmitOk(false);
    setSubmitting(true);
    const token = await executeRecaptcha("agenda_7mo_dia_walkin_mvd");

    var form = new FormData();
    form.append("apellido", values.apellido);
    form.append("nombres", values.nombres);
    form.append("tipo_doc", values.tipo_doc);
    form.append("nro_doc", values.nro_doc);
    form.append("telefono", values.telefono);
    form.append("email", values.email);
    form.append("tuvo_covid", values.tuvo_covid);
    form.append("fecha_diagnostico", values.fecha_diagnostico);
    form.append("lugar_solicitado", values.lugar_solicitado);
    form.append("fecha_solicitada", values.fecha_solicitada);
    form.append("forma_ingreso", values.forma_ingreso);
    form.append("nro_pasaje_bqb", values.nro_pasaje_bqb);
    form.append("comentarios", values.comentarios);
    form.append("token", token);

    const response = await axios({
      method: "post",
      url: "//covid19.atgen.com.uy/email/mail_agenda_7mo_dia_mvd.php",
      data: form,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });

    if (response.status === 200) {
      setShowSubmitOk(true);
      setSubmitting(false);
    } else {
      setShowSubmitError(true);
      setSubmitting(false);
      console.error("There was an error!", response);
    }
  };

  const handleFechaChange = (e, handleChange) => {
    handleChange(e);
  };

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span>
          Redirigiendo a <a href={linkMP}>{linkMP}</a>
        </span>
      );
    }
    if (seconds === 1) {
      return <span>Redirigiendo en 1 segundo</span>;
    }
    return <span>Redirigiendo en {seconds} segundos</span>;
  };

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="0"
          className="w-100"
        >
          <div className="d-flex justify-content-between align-items-center pointer">
            <div className="mb-0 mr-2 text-left">
              <h5 className="font-weight-bold text-left">Autotest y Walk In</h5>
              <p className="font-weight-bold mb-1">Carrasco.</p>
              <p className="font-weight-light mb-1">
                Precio: $3.950 (IVA y timbre incluído).
              </p>
              <p className="font-weight-light mb-1">
                Resultados en 24 hs. Se enviará al correo electrónico ingresado.
              </p>
            </div>
            <BtnMas />
          </div>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <p>
            Usted seleccionó la opción Autotest y Walk In. A continuación se
            encuentran los días y horarios de funcionamiento de los centros de
            toma de muestra. Podrá asistir en el horario de su preferencia:
          </p>
          <ul>
            <li>
              <div className="font-weight-bold">
                AUTOTEST Y WALK IN MONTEVIDEO - CARRASCO
              </div>
              <ul>
                <li>
                  Ubicación: Centro de Eventos del Latu: Av. Saldún de Rodríguez
                  2037 esquina Bolonia.
                </li>
                <li>
                  Horario: Lunes a Sábados de 8am a 14pm.
                </li>
              </ul>
            </li>
            
          </ul>
          <p>
            <strong>Complete sus datos para comenzar la reserva</strong>
          </p>
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="mx-auto mt-3">
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="apellido">
                      <Form.Label>Apellidos</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Tus apellidos"
                        name="apellido"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apellido}
                        className={
                          touched.apellido && errors.apellido ? "error" : null
                        }
                      />
                      <ErrorMessage
                        name="apellido"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="nombres">
                      <Form.Label>Nombre completo</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Tu nombre completo"
                        name="nombres"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombres}
                        className={
                          touched.nombres && errors.nombres ? "error" : null
                        }
                      />
                      <ErrorMessage
                        name="nombres"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="tipo_doc">
                      <Form.Label>Tipo de documento</Form.Label>
                      <Form.Control
                        as="select"
                        name="tipo_doc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tipo_doc}
                        className={
                          touched.tipo_doc && errors.tipo_doc ? "error" : null
                        }
                      >
                        <>
                          <option value="" disabled>
                            -- Seleccione una opción --
                          </option>
                          <option value="CI">Cédula de identidad</option>
                          <option value="Pasaporte">Pasaporte</option>
                          <option value="Otro">Otro</option>
                        </>
                      </Form.Control>
                      <ErrorMessage
                        name="tipo_doc"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="nro_doc">
                      <Form.Label>Nº de documento</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="El nº de tu documento"
                        name="nro_doc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nro_doc}
                        className={
                          touched.nro_doc && errors.nro_doc ? "error" : null
                        }
                      />
                      <ErrorMessage
                        name="nro_doc"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="telefono">
                      <Form.Label>Tu teléfono o celular</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Tu teléfono o celular"
                        name="telefono"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telefono}
                        className={
                          touched.telefono && errors.telefono ? "error" : null
                        }
                      />
                      <ErrorMessage
                        name="telefono"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Tu email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={
                          touched.email && errors.email ? "error" : null
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="tuvo_covid">
                      <Form.Label>¿Tuvo COVID?</Form.Label>
                      <Form.Control
                        as="select"
                        name="tuvo_covid"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tuvo_covid}
                        className={
                          touched.tuvo_covid && errors.tuvo_covid
                            ? "error"
                            : null
                        }
                      >
                        <option value="" disabled>
                          -- Seleccione una opción --
                        </option>
                        <option value="Si">Si, ya tuve COVID.</option>
                        <option value="No">No, aún no tuve COVID.</option>
                      </Form.Control>
                      <ErrorMessage
                        name="tuvo_covid"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  {values.tuvo_covid &&
                    values.tuvo_covid.toLowerCase() === "si" && (
                      <Col xs={12} md={6}>
                        <Form.Group controlId="fecha_diagnostico">
                          <Form.Label>Fecha de diagnóstico de COVID</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Fecha de diagnóstico"
                            name="fecha_diagnostico"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fecha_diagnostico}
                            className={
                              touched.fecha_diagnostico &&
                              errors.fecha_diagnostico
                                ? "error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="fecha_diagnostico"
                            component="div"
                            className="error-message"
                          />
                        </Form.Group>
                      </Col>
                    )}
                  <Col xs={12} md={6}>
                    <Form.Group controlId="lugar_solicitado">
                      <Form.Label>Lugar para realizar el test PCR</Form.Label>
                      <Form.Control
                        as="select"
                        name="lugar_solicitado"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lugar_solicitado}
                        className={
                          touched.lugar_solicitado && errors.lugar_solicitado
                            ? "error"
                            : null
                        }
                      >
                        <option value="" disabled>
                          -- Seleccione una opción --
                        </option>
                        <option value="Autotest Montevideo LATU">
                          Autotest Montevideo LATU
                        </option>
                        
                      </Form.Control>
                      <ErrorMessage
                        name="lugar_solicitado"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="fecha_solicitada">
                      <Form.Label>Fecha de realización del test PCR</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Fecha de realización del test"
                        name="fecha_solicitada"
                        onChange={(e) => handleFechaChange(e, handleChange)}
                        onBlur={handleBlur}
                        value={values.fecha_solicitada}
                        className={
                          touched.fecha_solicitada && errors.fecha_solicitada
                            ? "error"
                            : null
                        }
                      />
                      <ErrorMessage
                        name="fecha_solicitada"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forma_ingreso">
                      <Form.Label>Forma de ingreso al país</Form.Label>
                      <Form.Control
                        as="select"
                        name="forma_ingreso"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.forma_ingreso}
                        className={
                          touched.forma_ingreso && errors.forma_ingreso
                            ? "error"
                            : null
                        }
                      >
                        <option value="" disabled>
                          -- Seleccione una opción --
                        </option>
                        <option value="Aeropuerto">Aeropuerto</option>
                        <option value="Buquebus">Buquebus</option>
                      </Form.Control>
                      <ErrorMessage
                        name="forma_ingreso"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                  {values.forma_ingreso &&
                    values.forma_ingreso.toLowerCase() === "buquebus" && (
                      <Col xs={12} md={6}>
                        <Form.Group controlId="nro_pasaje_bqb">
                          <Form.Label>Nº de pasaje de Buquebus</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nº de pasaje de Buquebus"
                            name="nro_pasaje_bqb"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nro_pasaje_bqb}
                            className={
                              touched.nro_pasaje_bqb && errors.nro_pasaje_bqb
                                ? "error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="nro_pasaje_bqb"
                            component="div"
                            className="error-message"
                          />
                        </Form.Group>
                      </Col>
                    )}
                  <Col xs={12} md={6}>
                    <Form.Group controlId="comentarios">
                      <Form.Label>Comentarios</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Comentarios"
                        name="comentarios"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comentarios}
                        className={
                          touched.comentarios && errors.comentarios
                            ? "error"
                            : null
                        }
                      />
                      <ErrorMessage
                        name="comentarios"
                        component="div"
                        className="error-message"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={12} sm={6}>
                    <p className="mb-0">
                      <strong>NOTAS</strong>
                    </p>
                    <ul>
                      <li className="mb-0">
                        Realizamos hisopados nasofaríngeos únicamente en niños
                        mayores de 1 año.
                      </li>
                      <li className="mb-0">
                        Los resultados se emiten tanto en ingles como en
                        español.
                      </li>
                      <li className="mb-0">
                        Se permite el ingreso en vehículo, a pie o motocicleta.
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} sm={6}>
                    <p className="mb-0">
                      <strong>POLÍTICA DE CANCELACIÓN</strong>
                    </p>
                    <p className="mb-0">
                      Usted tiene derecho a revocar la presente contratación,
                      sin responsabilidad alguna, debiendo para ello comunicarlo
                      en un plazo de hasta 24 horas antes de la hora agendada.
                      Vencido dicho plazo, la revocación generará un costo
                      equivalente al 100 % del monto de los servicios
                      contratados. El reintegro se realizará por la misma vía
                      por la que se efectuó el pago. El tiempo que demore en
                      acreditarse el reembolso depende de cada tarjeta y/o Banco
                      emisor que opere en cada caso. Si Ud. ha modificado su
                      fecha y hora de agenda, se tomará en cuenta la fecha y
                      hora de la reserva original para determinar si se cumple
                      con el criterio de cancelación.
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={12} className="text-center">
                    <Alert
                      variant="success"
                      show={showSubmitOk}
                      onClose={() => setShowSubmitOk(false)}
                    >
                      <div className="text-center">
                        Su reserva fue registrada correctamente. A continuación
                        se le redireccionará hacia MercadoPago para que realice
                        el pago del test.
                      </div>

                      <div className="text-center contador">
                        <Countdown
                          date={Date.now() + 7000}
                          onComplete={() => {
                            window.location.href = linkMP;
                          }}
                          renderer={countdownRenderer}
                        />
                      </div>
                    </Alert>
                    <Alert
                      variant="danger"
                      dismissible
                      show={showSubmitError}
                      onClose={() => setShowSubmitError(false)}
                    >
                      Ocurrió un error al enviar la agenda. Por favor reintente.
                    </Alert>
                    {!showSubmitOk && (
                      <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting ? (
                          <span>Enviar y proceder al pago</span>
                        ) : (
                          <span>Enviando...</span>
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AutotestWalkInCarrascoParqueRodo;
