export const HOME = '/';
export const FAQ = '/preguntas-frecuentes';
export const ME_VOY_DE_VIAJE = '/me-voy-de-viaje';
export const LLEGUE_DE_VIAJE = '/llegue-de-viaje';
export const VIAJO_EN_BARCO = '/viajo-en-barco';
export const MI_RESULTADO = '/mi-resultado';
export const AGENDA_AEROPUERTO = '/agenda/aeropuerto';
export const AGENDA_AUTO_TEST = 'https://www.atgendaweb.com/';
export const AGENDA_DOMICILIO = 'https://atgendaweb.com/domicilio';
export const AGENDA_ANTIGENO_GX = 'https://www.atgendaweb.com/agendaweb/servlet/com.agendaweb.linkfrontend';
export const AGENDA_CANCELAR = '/agenda/cancelar';
export const AGENDA_MODIFICAR = '/agenda/modificar';
export const AGENDA = '/agenda';
export const QUIENES_SOMOS = '/quienes-somos';
export const INFO_COVID = '/informacion-covid-19';
export const CONTACTO = '/contacto';
export const APP_CORONAVIRUS_UY = '/app-coronavirus-uy';
export const AGENDA_QR = 'https://www.atgendaweb.com/qr';
export const AGENDA_AEROPUERTO_LINK = 'https://www.atgendaweb.com/aeropuerto';
export const AGENDA_ANTIGENO = '/autotest-antigeno';
export const TEST_7MO_DIA = '/7-dia';
