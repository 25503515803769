import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import BtnMenu from '../../molecules/btnMenu';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';
import agendaBlanca from '../../../assets/imgs/agenda_blanca.png';
import BtnAgenda from '../../molecules/btnAgenda';
import axios  from 'axios';
import moment from 'moment';

const AgendaModificar = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showSubmitOk, setShowSubmitOk] = useState(false);
    const [showSubmitError, setShowSubmitError] = useState(false);

    const formInitialValues = {
        primer_apellido: '',
        segundo_apellido: '',
        nombres: '',
        telefono: '',
        email: '',
        nro_doc: '',
        lugar_reservado: '',
        fecha_reservado: '',
        lugar_solicitado: '',
        fecha_solicitado: '',
        comentarios: '',
        nro_reserva: ''
    };

    const formValidationSchema = yup.object().shape({
        primer_apellido: yup.string().required("Debe ingresar su primer apellido."),
        segundo_apellido: yup.string().required("Debe ingresar su segundo apellido."),
        nombres: yup.string().required("Debe ingresar sus nombres."),
        telefono: yup.string().required("Debe ingresar su teléfono."),
        email: yup.string().email("Debe ingresar un email válido.").required("Debe ingresar su email."),
        nro_doc: yup.string().required("Debe ingresar el número de documento."),
        lugar_reservado: yup.string().required("Debe ingresar el lugar reservado para realizar el test."),
        fecha_reservado: yup.date().required("Debe ingresar la fecha de su test."),
        lugar_solicitado: yup.string().required("Debe ingresar el nuevo lugar solicitado para realizar el test."),
        fecha_solicitado: yup.date().required("Debe ingresar la nueva fecha de su test.")
            .when('lugar_solicitado', (lugar_solicitado, schema) => {
                if (!!lugar_solicitado && lugar_solicitado === "Autotest_Parque_Rodo") {
                    return schema.test({
                        test: (fecha_solicitado) => {
                            return moment(fecha_solicitado).day() !== 0;
                        },
                        message: 'Seleccione un día que no sea domingo.'
                      });
                }
            }),
        comentarios: yup.string(),
        nro_reserva: yup.string().required("Debe ingresar su número de reserva.")
    });

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setShowSubmitError(false);
        setShowSubmitOk(false);
        setSubmitting(true);
        const token = await executeRecaptcha("agenda_modificar");
        
        var form = new FormData();
        form.append('primer_apellido', values.primer_apellido);
        form.append('segundo_apellido', values.segundo_apellido);
        form.append('nombres', values.nombres);
        form.append('telefono', values.telefono);
        form.append('email', values.email);
        form.append('nro_doc', values.nro_doc);
        form.append('lugar_reservado', values.lugar_reservado);
        form.append('fecha_reservado', values.fecha_reservado);
        form.append('lugar_solicitado', values.lugar_solicitado);
        form.append('fecha_solicitado', values.fecha_solicitado);
        form.append('comentarios', values.comentarios.length > 0 ? values.comentarios : '-');
        form.append('nro_reserva', values.nro_reserva);
        form.append('token', token);

        const response = await axios({
            method: 'post',
            url: '//covid19.atgen.com.uy/email/mail_agenda_modificacion.php',
            data: form,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if (response.status === 200) {
            setShowSubmitOk(true);
            resetForm();
            setSubmitting(false);
        } else {
            setShowSubmitError(true);
            setSubmitting(false);
            console.error('There was an error!', response);
        }
    };

    return (
        <Row className="flex-grow-1 mr-0 ml-0">
            <Col xs={12} className="p-0">
                <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 pt-5 pb-3">
                    <img className="img-encabezado mt-2" src={agendaBlanca} alt="Modificar agenda" />
                    <h3>Modificar agenda</h3>
                </Jumbotron>
            </Col>
            <Container>
                <Row>
                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <p className="mt-3">
                            Por modificaciones de agenda o lugar de realización de test, complete el siguiente formulario.
                        </p>
                       
                        <hr />
                        <h5>Modificar agenda</h5>
                        <Formik
                            initialValues={formInitialValues}
                            validationSchema={formValidationSchema}
                            onSubmit={formSubmit}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit} className="mx-auto">
                                    <Row>
                                    <Col xs={12} md={6}>
                                            <Form.Group controlId="primer_apellido">
                                                <Form.Label>Primer apellido</Form.Label>
                                                <Form.Control type="text" placeholder="Tu primer apellido" name="primer_apellido" onChange={handleChange} onBlur={handleBlur} value={values.primer_apellido} className={touched.primer_apellido && errors.primer_apellido ? "error" : null} />
                                                <ErrorMessage name="primer_apellido" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="segundo_apellido">
                                                <Form.Label>Segundo apellido</Form.Label>
                                                <Form.Control type="text" placeholder="Tu segundo apellido" name="segundo_apellido" onChange={handleChange} onBlur={handleBlur} value={values.segundo_apellido} className={touched.segundo_apellido && errors.segundo_apellido ? "error" : null} />
                                                <ErrorMessage name="segundo_apellido" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nombres">
                                                <Form.Label>Nombre completo</Form.Label>
                                                <Form.Control type="text" placeholder="Tu nombre completo" name="nombres" onChange={handleChange} onBlur={handleBlur} value={values.nombres} className={touched.nombres && errors.nombres ? "error" : null} />
                                                <ErrorMessage name="nombres" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="telefono">
                                                <Form.Label>Teléfono</Form.Label>
                                                <Form.Control type="text" placeholder="Tu teléfono" name="telefono" onChange={handleChange} onBlur={handleBlur} value={values.telefono} className={touched.telefono && errors.telefono ? "error" : null} />
                                                <ErrorMessage name="telefono" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Tu email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className={touched.email && errors.email ? "error" : null} />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nro_doc">
                                                <Form.Label>Número de documento</Form.Label>
                                                <Form.Control type="text" placeholder="Tu nº de documento" name="nro_doc" onChange={handleChange} onBlur={handleBlur} value={values.nro_doc} className={touched.nro_doc && errors.nro_doc ? "error" : null} />
                                                <ErrorMessage name="nro_doc" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="lugar_reservado">
                                                <Form.Label>Lugar donde reservó</Form.Label>
                                                <Form.Control as="select" name="lugar_reservado" onChange={handleChange} onBlur={handleBlur} value={values.lugar_reservado} className={touched.lugar_reservado && errors.lugar_reservado ? "error" : null}>
                                                    <option value="" disabled>-- Seleccione una opción --</option>
                                                    <option value="Autotest_Aeropuerto">Aeropuerto</option> 
                                                    <option value="Autotest_Montevideo">Autotest Latu</option>
                                                    <option value="Autotest_Punta_del_Este">Autotest Enjoy</option>
                                                    <option value="Domicilio">Domicilio</option>
                                                </Form.Control>
                                                <ErrorMessage name="lugar_reservado" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="fecha_reservado">
                                                <Form.Label>Fecha de test</Form.Label>
                                                <Form.Control type="date" placeholder="Fecha de test" name="fecha_reservado" onChange={handleChange} onBlur={handleBlur} value={values.fecha_reservado} className={touched.fecha_reservado && errors.fecha_reservado ? "error" : null} />
                                                <ErrorMessage name="fecha_reservado" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nro_reserva">
                                                <Form.Label>Nº de reserva</Form.Label>
                                                    <Form.Control type="text" 
                                                              placeholder="Tus nº de reserva" 
                                                              name="nro_reserva" 
                                                              onChange={handleChange} 
                                                              onBlur={handleBlur} 
                                                              value={values.nro_reserva} 
                                                              className={touched.nro_reserva && errors.nro_reserva ? "error" : null} 
                                                    />
                                                <ErrorMessage name="nro_reserva" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="lugar_solicitado">
                                                <Form.Label>Nuevo lugar para realizar el test</Form.Label>
                                                <Form.Control as="select" name="lugar_solicitado" onChange={handleChange} onBlur={handleBlur} value={values.lugar_solicitado} className={touched.lugar_solicitado && errors.lugar_solicitado ? "error" : null}>
                                                    <option value="" disabled>-- Seleccione una opción --</option>
                                                    <option value="Autotest_Aeropuerto">Aeropuerto</option>
                                                    <option value="Autotest_Montevideo">Autotest Latu</option>
                                                    <option value="Autotest_Punta_del_Este">Autotest Enjoy</option>
                                                    <option value="Domicilio">Domicilio</option>
                                                </Form.Control>
                                                <ErrorMessage name="lugar_solicitado" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="fecha_solicitado">
                                                <Form.Label>Nueva fecha para realizar el test</Form.Label>
                                                <Form.Control 
                                                  type="date" 
                                                  placeholder="Nueva fecha para realizar el test" 
                                                  name="fecha_solicitado" 
                                                  onChange={handleChange} 
                                                  onBlur={handleBlur} 
                                                  value={values.fecha_solicitado} 
                                                  className={touched.fecha_solicitado && errors.fecha_solicitado ? 
                                                  "error" : null} 
                                                />
                                                <ErrorMessage 
                                                  name="fecha_solicitado" 
                                                  component="div" 
                                                  className="error-message" 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="comentarios">
                                                <Form.Label>Comentarios</Form.Label>
                                                <Form.Control as="textarea" rows={4} placeholder="Tus comentarios" name="comentarios" onChange={handleChange} onBlur={handleBlur} value={values.comentarios} className={touched.comentarios && errors.comentarios ? "error" : null} />
                                                <ErrorMessage name="comentarios" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Alert variant='success' dismissible show={showSubmitOk} onClose={() => setShowSubmitOk(false)}>
                                                Datos enviados correctamente. Nuestro equipo procesará su solicitud.
                                            </Alert>
                                            <Alert variant='danger' dismissible show={showSubmitError} onClose={() => setShowSubmitError(false)}>
                                                Ocurrió un error al enviar la modificación. Por favor reintente.
                                            </Alert>
                                            <Button className="float-right" variant="primary" type="submit" disabled={isSubmitting}>
                                                {!isSubmitting ? (<span>Enviar</span>) : (<span>Enviando...</span>)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                        <div className="d-flex justify-content-around">
                            <BtnMenu />
                            <BtnAgenda />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default AgendaModificar;