import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import avionAterrizando from '../../../assets/imgs/avion_aterrizando.png';
import avionDespegando from '../../../assets/imgs/avion_despegando.png';
import preguntasFrecuentes from '../../../assets/imgs/faq.png';
import resultado from '../../../assets/imgs/resultado.png';
import app_covid_uy from '../../../assets/imgs/app_covid_uy.png';
import { INFO_COVID, LLEGUE_DE_VIAJE, ME_VOY_DE_VIAJE, MI_RESULTADO, APP_CORONAVIRUS_UY } from '../../../routes';
import BtnAgenda from '../../molecules/btnAgenda';
import BtnAgendaBuquebus from '../../molecules/btnAgendaBqb';
import BtnDetalleApp from '../../molecules/btnDetalleApp';

const Home = () => {
    return (
        <Row className="flex-grow-1 mr-0 ml-0">
            <Col xs={12} className="col-jumbo-portada p-0">
                <Jumbotron fluid className="jumbotron-home-bg mb-0" />
            </Col>
            <Container className="container-home-btns">
                <Row>

                    {/* Empieza boton me voy de viaje */}
                    <Col xs={6} md={4} className="d-flex flex-column align-items-center my-3">
                        <div className="btn-seccion">
                            <Link to={ME_VOY_DE_VIAJE} className="d-flex flex-column align-items-center"> 
                                <img src={avionDespegando} alt="Me voy de viaje" />
                                <div>Me voy de viaje</div>
                            </Link>
                        </div>
                    </Col>
                    {/* Termina boton */}
                    
                    <Col xs={6} md={4} className="d-flex flex-column align-items-center my-3">
                        <BtnAgenda />
                    </Col>
                    
                    <Col xs={6} md={4} className="d-flex flex-column align-items-center my-3">
                        <div className="btn-seccion">
                            <Link to={LLEGUE_DE_VIAJE} className="d-flex flex-column align-items-center"> 
                                <img src={avionAterrizando} alt="Vuelvo de viaje" />
                                <div>Vuelvo de viaje</div>
                            </Link>
                        </div>
                    </Col>
                   
                   {/* Empieza Boton de viajo por BQB */}
                     <Col xs={6} md={4} className="d-flex flex-column align-items-center my-3">
                       {/* <div></div> */}
                       <BtnAgendaBuquebus textoAgenda={false} />
                    </Col> 
                   {/* Termina boton */}
                   
                    <Col xs={6} md={4} className="d-flex flex-column align-items-center my-3">
                        <div className="btn-seccion">
                            <Link to={MI_RESULTADO} className="d-flex flex-column align-items-center"> 
                                <img src={resultado} alt="Mi resultado" />
                                <div>Mi resultado</div>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={6} md={4} className="d-flex flex-column align-items-center my-3">
                        <BtnDetalleApp />
                    </Col>
                    <Col xs={12} className="d-flex flex-column align-items-center my-3">
                        <div className="btn-seccion">
                            <Link to={INFO_COVID} className="d-flex flex-column align-items-center"> 
                                <img src={preguntasFrecuentes} alt="Preguntas frecuentes" />
                                <div>Preguntas frecuentes</div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default Home;