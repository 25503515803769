import agendaBlanca from "../../../assets/imgs/agenda_blanca.png";
import { AGENDA_DOMICILIO } from "../../../routes";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import avionDespegando from "../../../assets/imgs/avion_despegando.png";
import BtnAgendaAutotest from "../../molecules/btnAgendaAutotest";
import BtnAgendaAeropuerto from "../../molecules/btnAgendaAeropuerto";
import BtnMenu from "../../molecules/btnMenu";
import BtnMas from "../../molecules/btnMas";

const MeVoyDeViaje = () => {
  return (
    <Row className="flex-grow-1 mr-0 ml-0">
      <Col xs={12} className="p-0">
        <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
          <img
            className="img-encabezado mt-2"
            src={avionDespegando}
            alt="Me voy de viaje"
          />
          <h3>Me voy de viaje</h3>
        </Jumbotron>
      </Col>
      <Container className="mt-3">
        <Row>
          <Col xs={12} md={6}>
            <h5 className="my-2">¿Qué debo hacer?</h5>
            <p>
              En algunos destinos se exige la presentación de un informe
              realizado por un laboratorio clínico que acredite un resultado
              negativo para el test de detección de SARS-CoV-2 realizado antes
              de partir. Dicho informe puede ser solicitado en el momento de
              realizar el check-in previo a la partida o al arribar en el
              aeropuerto de destino.
            </p>
            <p>
              Los requerimientos varían según el país de destino, tanto en el
              tipo de técnica diagnóstica, el tiempo máximo admitido desde la
              realización del estudio hasta el momento de la llegada y el idioma
              en que debe estar redactado el informe.
            </p>
            <p>
              La técnica de referencia admitida en todo el mundo es el RT-PCR,
              sin embargo, en algunos destinos también se admiten otras técnicas
              diagnósticas. Por otra parte, varios países exigen que el informe esté
              redactado en inglés.
            </p>
            <p>
              Es recomendable asesorarse con su agente de viajes, aerolínea y/o
              la delegación consular del país al que viaja. Una vez obtenida la
              información es importante verificar que el laboratorio donde se
              hará el estudio utiliza la técnica diagnóstica adecuada y que el
              tiempo de entrega del informe es adecuado.
            </p>

            <hr className="d-md-none" />
          </Col>
          <Col xs={12} md={6}>
            <h5 className="my-2">Tests ATGen</h5>
            <p>
              <strong>
              ATGen ofrece la posibilidad de realizarse el test RT-PCR y Antígeno en nuestro laboratorio.
              </strong>
            </p>

            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="w-100"
                  >
                    <div className="d-flex justify-content-between align-items-center pointer">
                      <h5 className="mb-0 mr-2">Test PCR en Aeropuerto</h5>
                      <BtnMas />
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                      Puede realizarlo antes de la partida del vuelo coordinando con el laboratorio
                      de forma previa. El resultado se emite en español e inglés, y contiene código QR.
                    </p>
                    <div className="mt-2 mb-3">
                      <BtnAgendaAeropuerto />
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="w-100"
                  >
                    <div className="d-flex justify-content-between align-items-center pointer">
                      <h5 className="mb-0 mr-2">Test Antígeno en Aeropuerto</h5>
                      <BtnMas />
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>
                      Se realiza previa coordinacion con el laboratorio llamando al (+598) 2 600 6001. El pago se efectua mediante tarjeta de crédito o débito 
                      al momento de realizarsce el estudio. El resultado se emite en español e inglés, y contiene código QR.
                    </p>
                    <div className="mt-2 mb-4">
                      <BtnAgendaAeropuerto irAntigenos />
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="w-100"
                  >
                    <div className="d-flex justify-content-between align-items-center pointer">
                      <h5 className="mb-0 mr-2">Test PCR o Antígeno</h5>
                      <BtnMas />
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <p>
                    Contamos con Laboratorio ubicado en Carrasco donde podrás realizarte el test de PCR 
                    o antígeno por viaje, con agenda previa.{" "}
                    </p>

                    <p>
                    <strong>Laboratorio central ATGen</strong>
                    <br />
                    <strong>Dirección:</strong> Av. Italia 6201 - Edificio Los Tilos - Of. 105 dentro del Parque Tecnológico LATU. 
                    <br /><br />
                    Con estacionamiento disponible. 
                    <br />
                    Abierto de lunes a viernes. 
                    </p>
                    {/* 
                    <ul>
                      <li>Carrasco (Centro de Eventos del Latu). Abierto de lunes a viernes. Con agenda previa.</li>
                      <li>Punta del Este. Abierto de lunes a sábados. Con agenda previa.</li>
                    </ul> 
                    */}
                    <BtnAgendaAutotest />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="w-100"
                  >
                    <div className="d-flex justify-content-between align-items-center pointer">
                      <h5 className="mb-0 mr-2">
                        Test PCR en domicilio
                      </h5>
                      <BtnMas />
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p>
                      Contamos con sistema de toma de muestra a domicilio tanto
                      en Montevideo como en el interior del país.
                    </p>
                    <ul>
                      <li>
                        Precio Montevideo , Ciudad de la costa y San José: $4.720 IVA 
                        y timbre incluido con tarjeta de débito o crédito.
                      </li>
                      <li>
                        Precio Domicilio en Maldonado e interior del país: $5.292 IVA 
                        y timbre incluido con tarjeta de débito o crédito.
                      </li>
                      <li>
                        Realizamos hisopados Nasofaríngeos únicamente en niños
                        mayores de 1 año.
                      </li>
                    </ul>
                    <p>
                      El informe de resultado se enviará al correo electrónico
                      ingresado.
                    </p>

                    <p>
                      <p>
                        Política de cancelación: Usted tiene derecho a revocar
                        la presente contratación, sin responsabilidad alguna,
                        debiendo para ello comunicarlo en un plazo de hasta 24
                        horas antes de la hora agendada. Vencido dicho plazo, la
                        revocación generará un costo equivalente al 100 % del
                        monto de los servicios contratados. El reintegro se
                        realizará por la misma vía por la que se efectuó el
                        pago. El tiempo que demore en acreditarse el reembolso
                        depende de cada tarjeta y/o Banco emisor que opere en
                        cada caso. Si Ud. ha modificado su fecha y hora de
                        agenda, se tomará en cuenta la fecha y hora de la
                        reserva original para determinar si se cumple con el
                        criterio de cancelación.
                      </p>
                    </p>

                    <div className="btn-seccion">
                      <a
                        href={AGENDA_DOMICILIO}
                        target={"_blank"}
                        rel="noreferrer"
                        className="d-flex flex-column align-items-center"
                      >
                        <img src={agendaBlanca} alt="Agenda domicilio" />
                        <div>Agendarme en domicilio</div>
                      </a>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
        
        <Row>
          <Col>
            <hr />
            <div className="d-flex justify-content-center">
              <BtnMenu />
            </div>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default MeVoyDeViaje;
