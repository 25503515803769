import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import agendaBlanca from "../../../assets/imgs/agenda_blanca.png";
import preguntasFrecuentes from "../../../assets/imgs/faq.png";
import { AGENDA_CANCELAR, AGENDA_MODIFICAR, INFO_COVID } from "../../../routes";
import BtnAgendaAeropuerto from "../../molecules/btnAgendaAeropuerto";
import BtnAgendaAutotest from "../../molecules/btnAgendaAutotest";
import BtnAgendaBuquebus from "../../molecules/btnAgendaBqb";
import BtnAgendaDomicilio from "../../molecules/btnAgendaDomicilio";
import BtnDetalleApp from "../../molecules/btnDetalleApp";
import BtnMenu from "../../molecules/btnMenu";

const Agenda = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Row className="flex-grow-1 mr-0 ml-0">
        <Col xs={12} className="p-0">
          <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 pt-5 pb-3">
            <img
              className="img-encabezado mt-2"
              src={agendaBlanca}
              alt="Agenda"
            />
            <h3>Agenda</h3>
          </Jumbotron>
        </Col>
        <Container className="mt-3">
          <Container className="container-home-btns">
            <Row>
              <Col
                xs={6}
                md={4}
                className="d-flex flex-column align-items-center my-3"
              >
                <BtnAgendaAutotest />
                <div className="copa text-center">
                Laboratorio central ATGen
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                className="d-flex flex-column align-items-center my-3"
              >
                <BtnAgendaDomicilio />
              </Col>
              <Col
                xs={6}
                md={4}
                className="d-flex flex-column align-items-center my-3"
              >
                <BtnDetalleApp textoAgenda={true} />
              </Col>
              <Col
                xs={6}
                md={4}
                className="d-flex flex-column align-items-center my-3"
              >
                <BtnAgendaAeropuerto />
              </Col>
              <Col
                xs={6}
                md={4}
                className="d-flex flex-column align-items-center my-3"
              >
                <BtnAgendaBuquebus textoAgenda={true} />
              </Col>
              <Col
                xs={6}
                md={4}
                className="d-flex flex-column align-items-center my-3"
              >
                <div className="btn-seccion">
                  <a
                    href="#"
                    onClick={handleShowModal}
                    className="d-flex flex-column align-items-center"
                  >
                    <img src={agendaBlanca} alt="Gestionar mi agenda" />
                    <div>Gestionar mi agenda</div>
                  </a>
                </div>
              </Col>
              <Col
                xs={12}
                className="d-flex flex-column align-items-center my-3"
              >
                <div className="btn-seccion">
                  <Link
                    to={INFO_COVID}
                    className="d-flex flex-column align-items-center"
                  >
                    <img src={preguntasFrecuentes} alt="Preguntas frecuentes" />
                    <div>Preguntas frecuentes</div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col>
              <hr />
              <div className="d-flex justify-content-around">
                <BtnMenu />
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-atgen">
            Gestionar mi agenda
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6} className="d-flex flex-column align-items-center my-3">
              <div className="btn-seccion">
                <Link
                  to={AGENDA_MODIFICAR}
                  className="d-flex flex-column align-items-center"
                >
                  <img src={agendaBlanca} alt="Viajo por Buquebus" />
                  <div>Modificar mi agenda</div>
                </Link>
              </div>
            </Col>
            <Col xs={6} className="d-flex flex-column align-items-center my-3">
              <div className="btn-seccion">
                <Link
                  to={AGENDA_CANCELAR}
                  className="d-flex flex-column align-items-center"
                >
                  <img src={agendaBlanca} alt="Llegué de viaje" />
                  <div>Cancelar mi agenda</div>
                </Link>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Agenda;
