import { Link } from 'react-router-dom';
import barcoBlanco from '../../assets/imgs/barco_blanco.png';
import { VIAJO_EN_BARCO } from '../../routes';

const BtnAgendaBuquebus = (props) => {
    return (
        <div className="btn-seccion">
             <Link to={VIAJO_EN_BARCO} className="d-flex flex-column align-items-center">  
                <img src={barcoBlanco} alt="Viajo en Barco"/>
                {props.textoAgenda ? (
                    <div>Viajo en barco</div>
                ) : (
                    <div>Viajo en barco</div>
                )}
            </Link>
        </div>
    );
};

export default BtnAgendaBuquebus;