import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import BtnMenu from '../../molecules/btnMenu';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';
import axios  from 'axios';

const Contacto = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showContactoOk, setShowContactoOk] = useState(false);
    const [showContactoError, setShowContactoError] = useState(false);

    const contactFormInitialValues = {
        nombre: '',
        email: '',
        telefono: '',
        asunto: '',
        mensaje: ''
    };

    const contactFormValidationSchema = yup.object().shape({
        nombre: yup.string().required("Debe ingresar su nombre."),
        email: yup.string().email("Debe ingresar un email válido.").required("Debe ingresar su email."),
        telefono: yup.string().required("Debe ingresar su teléfono."),
        asunto: yup.string().required("Debe ingresar el asunto."),
        mensaje: yup.string().required("Debe ingresar el mensaje."),
    });

    const contactFormSubmit = async (values, { setSubmitting, resetForm }) => {
        setShowContactoError(false);
        setShowContactoOk(false);
        setSubmitting(true);
        const token = await executeRecaptcha("contacto");

        var form = new FormData();
        form.append('nombre', values.nombre);
        form.append('email', values.email);
        form.append('telefono', values.telefono);
        form.append('asunto', values.asunto);
        form.append('mensaje', values.mensaje);
        form.append('token', token);

        const response = await axios({
            method: 'post',
            url: '//covid19.atgen.com.uy/email/mail_contacto.php',
            data: form,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if (response.status === 200) {
            setShowContactoOk(true);
            resetForm();
            setSubmitting(false);
        } else {
            setShowContactoError(true);
            setSubmitting(false);
            console.error('There was an error!', response);
        }
    };

    return (
        <Row className="flex-grow-1 mr-0 ml-0">
            <Col xs={12} className="p-0">
                <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
                    <h3 className="mt-4">Contacto</h3>
                </Jumbotron>
            </Col>
            <Container fluid>
                <Row>
                    <Col className="p-0">
                        <iframe title="Ubicacion"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.1086205532793!2d-56.08116978427236!3d-34.87861863881048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f86faf2756de5%3A0x853619814a3a090!2sLos%20Tilos%20%7C%20LATU!5e0!3m2!1ses!2suy!4v1608668995928!5m2!1ses!2suy"
                            frameBorder="0"
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            className="iframe-ubicacion"
                        >
                        </iframe>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <h5 className="my-2">Contáctese con nosotros</h5>
                        <p>
                            Puede comunicarse con nosotros a través de los siguientes datos de contacto o dejarnos su mensaje desde el
                            formulario de contacto:
                        </p>
                        <ul>
                            <li>
                                <strong>WhatsApp:</strong> <a href="whatsapp://send?phone=+59897091919">097 09 19 19</a>
                            </li>
                            <li>
                                <strong>Teléfono:</strong> <a href="tel:26006001">(+598) 2600 6001</a>
                            </li>
                            <li>
                                <strong>E-mail:</strong> <a href="mailto:info@atgen.com.uy">info@atgen.com.uy</a>
                            </li>
                            <li>
                                <strong>Horario:</strong> Lunes a Sábados de 9:00 a 18:00 y Domingos de 10:00 a 14:00
                            </li>
                            <li>
                                <strong>Dirección:</strong> Avda. Italia 6201, Parque Tecnológico del LATU Edificio Los Tilos Of. 106, 11500, Montevideo (Uruguay)
                            </li>
                        </ul>
                        <hr className="d-md-none" />
                    </Col>
                    <Col xs={12} md={6}>
                        <h5 className="my-2">Por consultas o sugerencias</h5>
                        <Formik
                            initialValues={contactFormInitialValues}
                            validationSchema={contactFormValidationSchema}
                            onSubmit={contactFormSubmit}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit} className="mx-auto">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nombre">
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control type="text" placeholder="Tu nombre" name="nombre" onChange={handleChange} onBlur={handleBlur} value={values.nombre} className={touched.nombre && errors.nombre ? "error" : null} />
                                                <ErrorMessage name="nombre" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Tu email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className={touched.email && errors.email ? "error" : null} />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="telefono">
                                                <Form.Label>Teléfono</Form.Label>
                                                <Form.Control type="text" placeholder="Tu teléfono" name="telefono" onChange={handleChange} onBlur={handleBlur} value={values.telefono} className={touched.telefono && errors.telefono ? "error" : null} />
                                                <ErrorMessage name="telefono" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="asunto">
                                                <Form.Label>Asunto</Form.Label>
                                                <Form.Control type="text" placeholder="Asunto" name="asunto" onChange={handleChange} onBlur={handleBlur} value={values.asunto} className={touched.asunto && errors.asunto ? "error" : null} />
                                                <ErrorMessage name="asunto" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="mensaje">
                                                <Form.Label>Mensaje</Form.Label>
                                                <Form.Control as="textarea" rows={5} placeholder="Tu mensaje" name="mensaje" onChange={handleChange} onBlur={handleBlur} value={values.mensaje} className={touched.mensaje && errors.mensaje ? "error" : null} />
                                                <ErrorMessage name="mensaje" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Alert variant='success' dismissible show={showContactoOk} onClose={() => setShowContactoOk(false)}>
                                                Mensaje enviado correctamente. Nuestro equipo procesará su mensaje.
                                            </Alert>
                                            <Alert variant='danger' dismissible show={showContactoError} onClose={() => setShowContactoError(false)}>
                                                Ocurrió un error al enviar el mensaje. Por favor reintente.
                                            </Alert>
                                            <Button className="float-right" variant="primary" type="submit" disabled={isSubmitting}>
                                                {!isSubmitting ? (<span>Enviar</span>) : (<span>Enviando...</span>)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                        <div className="d-flex justify-content-center">
                            <BtnMenu />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default Contacto;