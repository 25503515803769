import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

import resultado from '../../../assets/imgs/resultado.png';
import BtnMenu from '../../molecules/btnMenu';

const MiResultado = () => {

    // Para solicitar su resultado por favor complete la siguiente información\n\nApellidos:\nNombres:\nNro. documento:\n\nFecha de toma de muestra:\nLugar:

    return (
        <Row className="flex-grow-1 mr-0 ml-0">
            <Col xs={12} className="p-0">
                <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 pt-5 pb-3">
                    <img className="img-encabezado mt-2" src={resultado} alt="Mi resultado" />
                    <h3>Mi resultado</h3>
                </Jumbotron>
            </Col>
            <Container className="mt-3">
            <Container className="container-home-btns">
                <Row>
                    <Col>
                      
                        <p>
                            Si Ud. se realizó el hisopado para el test de detección de SARS-CoV-2 en forma particular en uno de nuestros 
                            Auto-Test o en su domicilio, el informe con su resultado será enviado al mail que registró, por favor verificar 
                            correo no deseado (Spam).
                        </p>
                        
                        <p>
                            Si Ud. fue agendado a través de su <strong>Prestador de Salud</strong>, el resultado de su estudio será enviado al mismo.
                        </p>
                        <p>
                            Si usted no recibio su resultado puede comunicarse vía e-mail a través de: <a href="mailto:miresultado@atgen.uy?subject=Solicitud%20de%20mi%20resultado&body=Para%20solicitar%20su%20resultado%20por%20favor%20complete%20la%20siguiente%20informaci%C3%B3n%0A%0AApellidos%3A%0ANombres%3A%0ANro.%20documento%3A%0A%0AFecha%20de%20toma%20de%20muestra%3A%0ALugar%3A">miresultado@atgen.uy</a>
                            ó por telefono al (+598) 2 600 6001.
                        </p>
                    </Col>
                </Row>
            </Container>
                <Row>
                    <Col>
                        <hr />
                        <div className="d-flex justify-content-around">
                            <BtnMenu />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default MiResultado;