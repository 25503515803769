import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'; // eslint-disable-next-line
import Image from 'react-bootstrap/Image'; // eslint-disable-next-line
import mascara from '../../assets/imgs/novedades_mascara.jpg'; // eslint-disable-next-line
import atgenLogo from '../../assets/imgs/logo-atgen-color.png'; // eslint-disable-next-line
import Col from 'react-bootstrap/Col'; // eslint-disable-next-line
import Row from 'react-bootstrap/Row'; // eslint-disable-next-line

const ModalHorarios = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide}>
          
        </Modal>
    );
}

export default ModalHorarios;
