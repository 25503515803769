import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import BtnMenu from '../../molecules/btnMenu';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';
import agendaBlanca from '../../../assets/imgs/agenda_blanca.png';
import BtnAgenda from '../../molecules/btnAgenda';
import axios  from 'axios';

const AgendaCancelar = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showSubmitOk, setShowSubmitOk] = useState(false);
    const [showSubmitError, setShowSubmitError] = useState(false);

    const formInitialValues = {
        primer_apellido: '',
        segundo_apellido: '',
        nombres: '',
        telefono: '',
        email: '',
        nro_doc: '',
        lugar_reservado: '',
        fecha_reservado: '',
        como_pago: '',
        comentarios: '',
        nro_reserva: ''
    };

    const formValidationSchema = yup.object().shape({
        primer_apellido: yup.string().required("Debe ingresar su primer apellido."),
        segundo_apellido: yup.string().required("Debe ingresar su segundo apellido."),
        nombres: yup.string().required("Debe ingresar sus nombres."),
        telefono: yup.string().required("Debe ingresar su teléfono."),
        email: yup.string().email("Debe ingresar un email válido.").required("Debe ingresar su email."),
        nro_doc: yup.string().required("Debe ingresar el número de documento."),
        lugar_reservado: yup.string().required("Debe ingresar el lugar reservado para realizar el test."),
        fecha_reservado: yup.date().required("Debe ingresar la fecha de su test."),
        como_pago: yup.string().required("Debe seleccionar una opción de pago."),
        comentarios: yup.string(),
        nro_reserva: yup.string().required("Debe ingresar su número de reserva.")
    });

    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        setShowSubmitError(false);
        setShowSubmitOk(false);
        setSubmitting(true);
        const token = await executeRecaptcha("agenda_cancelar");
        
        var form = new FormData();
        form.append('primer_apellido', values.primer_apellido);
        form.append('segundo_apellido', values.segundo_apellido);
        form.append('nombres', values.nombres);
        form.append('telefono', values.telefono);
        form.append('email', values.email);
        form.append('nro_doc', values.nro_doc);
        form.append('lugar_reservado', values.lugar_reservado);
        form.append('fecha_reservado', values.fecha_reservado);
        form.append('como_pago', values.como_pago);
        form.append('comentarios', values.comentarios.length > 0 ? values.comentarios : '-');
        form.append('nro_reserva', values.nro_reserva);
        form.append('token', token);

        const response = await axios({
            method: 'post',
            url: '//covid19.atgen.com.uy/email/mail_agenda_cancelacion.php',
            data: form,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if (response.status === 200) {
            setShowSubmitOk(true);
            resetForm();
            setSubmitting(false);
        } else {
            setShowSubmitError(true);
            setSubmitting(false);
            console.error('There was an error!', response);
        }
    };

    return (
        <Row className="flex-grow-1 mr-0 ml-0">
            <Col xs={12} className="p-0">
                <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 pt-5 pb-3">
                    <img className="img-encabezado mt-2" src={agendaBlanca} alt="Cancelar agenda" />
                    <h3>Cancelar agenda</h3>
                </Jumbotron>
            </Col>
            <Container>
                <Row>
                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <p className="mt-3">
                            Si precisa cancelar su reserva, puede hacerlo completando sus datos en el 
                            siguiente formulario. Usted tiene derecho a revocar la presente contratación,
                            sin responsabilidad alguna, debiendo para ello comunicarlo
                            en un plazo de hasta 24 horas antes de la hora agendada. 
                            Vencido dicho plazo, la revocación generará un costo equivalente
                            al 100 % del monto de los servicios contratados. El reintegro se
                            realizará por la misma vía por la que se efectuó el pago.
                            El tiempo que demore en acreditarse el reembolso depende de cada tarjeta 
                            y/o Banco emisor que opere en cada caso.
                        </p>
                        <hr />
                        <h5>Cancelar agenda</h5>
                        <Formik
                            initialValues={formInitialValues}
                            validationSchema={formValidationSchema}
                            onSubmit={formSubmit}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit} className="mx-auto">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="primer_apellido">
                                                <Form.Label>Primer apellido</Form.Label>
                                                <Form.Control type="text" placeholder="Tu primer apellido" name="primer_apellido" onChange={handleChange} onBlur={handleBlur} value={values.primer_apellido} className={touched.primer_apellido && errors.primer_apellido ? "error" : null} />
                                                <ErrorMessage name="primer_apellido" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="segundo_apellido">
                                                <Form.Label>Segundo apellido</Form.Label>
                                                <Form.Control type="text" placeholder="Tu segundo apellido" name="segundo_apellido" onChange={handleChange} onBlur={handleBlur} value={values.segundo_apellido} className={touched.segundo_apellido && errors.segundo_apellido ? "error" : null} />
                                                <ErrorMessage name="segundo_apellido" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nombres">
                                                <Form.Label>Nombre completo</Form.Label>
                                                <Form.Control type="text" placeholder="Tu nombre completo" name="nombres" onChange={handleChange} onBlur={handleBlur} value={values.nombres} className={touched.nombres && errors.nombres ? "error" : null} />
                                                <ErrorMessage name="nombres" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="telefono">
                                                <Form.Label>Teléfono</Form.Label>
                                                <Form.Control type="text" placeholder="Tu teléfono" name="telefono" onChange={handleChange} onBlur={handleBlur} value={values.telefono} className={touched.telefono && errors.telefono ? "error" : null} />
                                                <ErrorMessage name="telefono" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Tu email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className={touched.email && errors.email ? "error" : null} />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nro_doc">
                                                <Form.Label>Número de documento</Form.Label>
                                                <Form.Control type="text" placeholder="Tu nº de documento" name="nro_doc" onChange={handleChange} onBlur={handleBlur} value={values.nro_doc} className={touched.nro_doc && errors.nro_doc ? "error" : null} />
                                                <ErrorMessage name="nro_doc" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="lugar_reservado">
                                                <Form.Label>Lugar donde reservó</Form.Label>
                                                <Form.Control as="select" name="lugar_reservado" onChange={handleChange} onBlur={handleBlur} value={values.lugar_reservado} className={touched.lugar_reservado && errors.lugar_reservado ? "error" : null}>
                                                    <option value="" disabled>-- Seleccione una opción --</option>
                                                    <option value="Autotest_Aeropuerto">Aeropuerto</option>
                                                    <option value="Autotest_Montevideo">Autotest Latu</option>
                                                    <option value="Autotest_Punta_del_Este">Autotest Enjoy</option>
                                                    <option value="Domicilio">Domicilio</option>
                                                </Form.Control>
                                                <ErrorMessage name="lugar_reservado" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="fecha_reservado">
                                                <Form.Label>Fecha de test</Form.Label>
                                                <Form.Control type="date" placeholder="Fecha de partida" name="fecha_reservado" onChange={handleChange} onBlur={handleBlur} value={values.fecha_reservado} className={touched.fecha_reservado && errors.fecha_reservado ? "error" : null} />
                                                <ErrorMessage name="fecha_reservado" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="nro_reserva">
                                                <Form.Label>Nº de reserva</Form.Label>
                                                <Form.Control type="text" placeholder="Tus nº de reserva" name="nro_reserva" onChange={handleChange} onBlur={handleBlur} value={values.nro_reserva} className={touched.nro_reserva && errors.nro_reserva ? "error" : null} />
                                                <ErrorMessage name="nro_reserva" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group controlId="como_pago">
                                                <Form.Label>¿Cómo pago?</Form.Label>
                                                <Form.Control as="select" name="como_pago" onChange={handleChange} onBlur={handleBlur} value={values.como_pago} className={touched.como_pago && errors.como_pago ? "error" : null} >
                                                    <option value="" disabled>-- Seleccione una opción --</option>
                                                    <option value="Transferencia_bancaria">Transferencia bancaria</option>
                                                    <option value="Plataforma_Mercado_Pago">Plataforma Mercado Pago</option>
                                                </Form.Control>
                                                <ErrorMessage name="como_pago" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group controlId="comentarios">
                                                <Form.Label>Comentarios</Form.Label>
                                                <Form.Control as="textarea" rows={4} placeholder="Tus comentarios" name="comentarios" onChange={handleChange} onBlur={handleBlur} value={values.comentarios} className={touched.comentarios && errors.comentarios ? "error" : null} />
                                                <ErrorMessage name="comentarios" component="div" className="error-message" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Alert variant='success' dismissible show={showSubmitOk} onClose={() => setShowSubmitOk(false)}>
                                                Datos enviados correctamente. Nuestro equipo procesará su solicitud.
                                            </Alert>
                                            <Alert variant='danger' dismissible show={showSubmitError} onClose={() => setShowSubmitError(false)}>
                                                Ocurrió un error al enviar la cancelación. Por favor reintente.
                                            </Alert>
                                            <Button className="float-right" variant="primary" type="submit" disabled={isSubmitting}>
                                                {!isSubmitting ? (<span>Enviar</span>) : (<span>Enviando...</span>)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                        <div className="d-flex justify-content-around">
                            <BtnMenu />
                            <BtnAgenda />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default AgendaCancelar;