import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import AutotestWalkInCarrascoParqueRodo from "./autotestWalkInCarrascoParqueRodo";
import AutotestWalkInPuntaDelEste from "./autotestWalkInPuntaDelEste";
import TestDomicilioCapitalesInterior from "./testDomicilioCapitalesInterior";
import TestDomicilioMvdCosta from "./testDomicilioMvdCosta";
import BtnMenu from "../../molecules/btnMenu";

const Test7moDia = () => {
  return (
    <>
      <Row className="flex-grow-1 mr-0 ml-0">
        <Col xs={12} className="p-0">
          <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 pt-5 pb-3">
            <h3 className="mt-4">Test PCR día 7</h3>
          </Jumbotron>
        </Col>
        <Container className="mt-3">
          <Row>
            <Col>
              <h4 className="texto-azul text-center mt-2 mb-4">
                Brindamos precios promocionales en los siguientes servicios:
              </h4>
              <Accordion>
                <AutotestWalkInCarrascoParqueRodo />
                <AutotestWalkInPuntaDelEste />
                <TestDomicilioMvdCosta />
                <TestDomicilioCapitalesInterior />
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <div className="d-flex justify-content-center">
                <BtnMenu />
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
};

export default Test7moDia;
