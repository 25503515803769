import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import ModalMapaAutotestMontevideo from '../../molecules/modalMapaAutotestMontevideo';
import ModalMapaAutotestMaldonado from '../../molecules/modalMapaAutotestMaldonado';
import app_covid_uy from '../../../assets/imgs/app_covid_uy.png';
import BtnMenu from '../../molecules/btnMenu';
import BtnAgendaQR from '../../molecules/btnAgendaQr';
import { useState } from 'react';

const AppCoronavirusUy = () => {
    const [showModalMapaAeropuerto, setShowModalMapaAeropuerto] = useState(false);
    const handleCloseModalMapaMontevideo = () => setShowModalMapaAeropuerto(false);
    const handleShowModalMapaMontevideo = () => setShowModalMapaAeropuerto(true);

    const [showModalMapaMaldonado, setShowModalMapaMaldonado] = useState(false);
    const handleCloseModalMapaMaldonado = () => setShowModalMapaMaldonado(false);
    const handleShowModalMapaMaldonado = () => setShowModalMapaMaldonado(true);

    return (
        <>
            <Row className="flex-grow-1 mr-0 ml-0">
                <Col xs={12} className="p-0">
                    <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 pt-5 pb-3">
                        <img className="img-encabezado mt-2" src={app_covid_uy} alt="App Coronavirus UY" />
                        <h3>App Coronavirus UY</h3>
                    </Jumbotron>
                </Col>
                <Container className="mt-3">
                <Container className="container-home-btns">
                    <Row>
                        <Col>
                            {/*<p>
                                Contamos con AUTOTESTS en Montevideo (Latu), como en Maldonado, para realizarte el test PCR utilizando el código QR de la aplicación CORONAVIRUS UY.
                            </p>*/}<br/>
                           {/* <ul>
                                <li>
                                    AUTOTEST MONTEVIDEO LATU: 
                                    Ubicado en Av. Saldún de Rodríguez 2037 esquina Bolonia - Centro de Eventos del LATU 
                                    <Button className="p-0 btn-donde-bqb" onClick={handleShowModalMapaMontevideo} variant="link" size="sm">(Ver en mapa)</Button>.
                                     Lunes a sábado de 8am a 14pm.
                                </li>

                                <li>
                                    AUTOTEST MALDONADO: 
                                    Ubicado en el Parking del Hotel Enjoy, entrada por Blvr. Artigas entre Biarritz y Chiverta 
                                    <Button className="p-0 btn-donde-bqb" onClick={handleShowModalMapaMaldonado} variant="link" size="sm">(Ver en mapa)</Button>. 
                                    Lunes a sábados de 8am a 13pm. 
                                </li>
                                <li>
                                    Realizamos hisopados Nasofaríngeos únicamente en niños mayores de 1 año.
                                </li>
                            
                            </ul>*/}
                            <p>
                                Únicamente los pacientes previamente agendados serán atendidos, presentando el código QR junto con el documento de identidad.
                            </p>
                            <ul>
                               {/* <li>
                                    AUTOTEST MONTEVIDEO LATU: 
                                    Ubicado en Av. Saldún de Rodríguez 2037 esquina Bolonia - Centro de Eventos del LATU 
                                    <Button className="p-0 btn-donde-bqb" onClick={handleShowModalMapaMontevideo} variant="link" size="sm">(Ver en mapa)</Button>.
                                     Lunes a sábado de 8am a 14pm.
                                </li>

                                <li>
                                    AUTOTEST MALDONADO: 
                                    Ubicado en el Parking del Hotel Enjoy, entrada por Blvr. Artigas entre Biarritz y Chiverta 
                                    <Button className="p-0 btn-donde-bqb" onClick={handleShowModalMapaMaldonado} variant="link" size="sm">(Ver en mapa)</Button>. 
                                    Lunes a sábados de 8am a 13pm. 
                                </li>*/}
                                <li>
                                    Realizamos hisopados Nasofaríngeos únicamente en niños mayores de 1 año.
                                </li>
                            
                            </ul>
                            <p>
                                *Por indicación de  test de antígeno contacte  a su prestador de salud.
                            </p>
                            <div className="d-flex justify-content-around">
                                <BtnAgendaQR />
                            </div>
                        </Col>
                    </Row>
                </Container>
                    <Row>
                        <Col>
                            <hr />
                            <div className="d-flex justify-content-around">
                                <BtnMenu />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <ModalMapaAutotestMontevideo show={showModalMapaAeropuerto} onHide={handleCloseModalMapaMontevideo} />
            <ModalMapaAutotestMaldonado show={showModalMapaMaldonado} onHide={handleCloseModalMapaMaldonado} />
        </>
    );
};

export default AppCoronavirusUy;