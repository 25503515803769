import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalMapaAutotestMaldonado = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide} backdropClassName='backdrop-mapa' className='modal-mapa'>
            <Modal.Header closeButton>
                <Modal.Title className="modal-title-atgen">Autotest Maldonado</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <iframe title="Ubicacion"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1635.1270988090332!2d-54.9380981929668!3d-34.95023699843913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDU3JzAwLjkiUyA1NMKwNTYnMTQuOSJX!5e0!3m2!1ses!2suy!4v1610123549417!5m2!1ses!2suy"
                        frameBorder="0"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                        className="iframe-ubicacion"
                    >
                </iframe>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalMapaAutotestMaldonado;