import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import atgenLogo from '../../../assets/imgs/logo-atgen-color.png';
import atgenLogoBlanco from '../../../assets/imgs/logo-atgen-blanco.png';
import menuNegro from '../../../assets/imgs/menu-negro.png';
import menuBlanco from '../../../assets/imgs/menu-blanco.png';
import { HOME, QUIENES_SOMOS, INFO_COVID, CONTACTO, AGENDA_MODIFICAR, AGENDA_CANCELAR, VIAJO_EN_BARCO, TEST_7MO_DIA } from '../../../routes';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ModalNovedades from '../../molecules/modalNovedades';

const ATGenNavBar = () => {
    let location = useLocation();
    const [logo, setLogo] = useState(atgenLogo);
    const [menu, setMenu] = useState(menuNegro);
    const [classNavLink, setClassNavLink] = useState('');

    const [showModalNovedades, setShowModalNovedades] = useState(false);
    const handleCloseModalNovedades = () => setShowModalNovedades(false);
    const handleShowModalNovedades = () => setShowModalNovedades(true);

    useEffect(() => {
        if (location, location.pathname === HOME) {
            setLogo(atgenLogo);
            setMenu(menuNegro);
            setClassNavLink('nav-link-bg-white');
        } else {
            setLogo(atgenLogoBlanco);
            setMenu(menuBlanco);
            setClassNavLink('nav-link-text-white');
        }

        let contactFormPresent = false;
        if (location.pathname === CONTACTO || location.pathname === AGENDA_CANCELAR || 
            location.pathname === AGENDA_MODIFICAR || location.pathname === VIAJO_EN_BARCO) {
            contactFormPresent = true;
        }

        if (!contactFormPresent) {
            setTimeout(() => {
                document.body.classList.remove('show-recaptcha-label');
                document.body.classList.add('hide-recaptcha-label');
            }, 500);
        } else {
            setTimeout(() => {
                document.body.classList.remove('hide-recaptcha-label');
                document.body.classList.add('show-recaptcha-label');
            }, 500);
        }
    }, [location]);

    useEffect(() => {
        setTimeout(() => {
            if(location.pathname !== TEST_7MO_DIA) {
                handleShowModalNovedades();
            }
        }, 500);
    }, []);

    return (
        <>
            <Navbar bg="transparent" collapseOnSelect expand="lg" >
                <Navbar.Brand as={Link} to={HOME}>
                    <img
                        src={logo}
                        height="50"
                        className="d-inline-block align-top"
                        alt="Logo ATGen"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-label="Abrir navegación" className="navbar-toggler collapsed">
                    <img 
                        src={menu} 
                        height="30"
                        className="d-inline-block align-top"
                        alt="Icono de menú"
                    />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="w-100 justify-content-around">
                        <Nav.Link className={classNavLink} eventKey="1" as={Link} to={HOME}>Inicio COVID-19</Nav.Link>
                        <Nav.Link className={classNavLink} eventKey="4" as={Link} to={INFO_COVID}>Preguntas frecuentes</Nav.Link>
                        <Nav.Link className={classNavLink} eventKey="3" as={Link} to={QUIENES_SOMOS}>Quiénes somos</Nav.Link>
                        <Nav.Link className={classNavLink} eventKey="5" as={Link} to={CONTACTO}>Contacto</Nav.Link>
                        <Nav.Link className={classNavLink} eventKey="2" href='http://www.atgen.com.uy/'>Volver a página principal</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <ModalNovedades show={showModalNovedades} onHide={handleCloseModalNovedades} />
        </>
    );
};

export default ATGenNavBar;