import { Link } from 'react-router-dom';
import menu from '../../assets/imgs/menu.png';
import { HOME } from '../../routes';

const BtnMenu = () => {
    return (
        <div className="btn-seccion">
            <Link to={HOME} className="d-flex flex-column align-items-center">
                <img src={menu} alt="Menú" />
                <div>Menú</div>
            </Link>
        </div>
    );
};

export default BtnMenu;