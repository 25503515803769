import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalMapaAutotestMontevideo = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide} backdropClassName='backdrop-mapa' className='modal-mapa'>
            <Modal.Header closeButton>
                <Modal.Title className="modal-title-atgen">Autotest Montevideo</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <iframe title="Ubicacion"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d818.2654382323641!2d-56.08021877074962!3d-34.87979559877433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f871cf9b86f2f%3A0xdcc48036c96341a6!2sCentro%20de%20Eventos%20del%20LATU!5e0!3m2!1ses!2suy!4v1610123090492!5m2!1ses!2suy"
                        frameBorder="0"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                        className="iframe-ubicacion"
                    >
                </iframe>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalMapaAutotestMontevideo;