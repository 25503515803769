import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

import certLSQA from '../../../assets/imgs/lsqa.png';
import certGMP from '../../../assets/imgs/gmp.png';
import certMSP from '../../../assets/imgs/msp.png';
import BtnMenu from '../../molecules/btnMenu';

const QuienesSomos = () => {
    return (
        <Row className="flex-grow-1 mr-0 ml-0">
            <Col xs={12} className="p-0">
                <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
                    <h3 className="mt-4">Quiénes somos</h3>
                </Jumbotron>
            </Col>
            <Container className="mt-3">
                <Row>
                    <Col>
                        <h5 className="my-2">Empresa</h5>
                        <p>
                            Somos  una empresa biotecnológica de capitales nacionales, fundada en el año 2001. El origen de ATGen como el primer 
                            spin off incubado en la Facultad de Ciencias de la Universidad de la República determina el carácter innovador que 
                            la define y le ha valido múltiples premios y reconocimientos.
                        </p>
                        <p>
                            En la actualidad contamos con 3 divisiones, Salud Humana, Salud Veterinaria y Co-Work abocadas en conjunto al desarrollo, 
                            la fabricación y representación de productos biotecnológicos innovadores, así como a brindar servicios a instituciones de 
                            salud pública y privada, nacionales y regionales. Somos la única empresa uruguaya que desarrolla y produce reactivos de 
                            diagnóstico in vitro utilizados por laboratorios clínicos y bancos de sangre en toda América Latina.
                        </p>
                        <hr />
                        <h5 className="my-2">Habilitaciones y certificaciones</h5>
                        <p>
                            ATGen cuenta con un laboratorio clínico especializado en técnicas de biología molecular, el cual brinda servicios a instituciones 
                            de salud pública y privada, nacional y regional. El laboratorio cumple con la correspondiente habilitación del MSP. 
                        </p>
                        <p>
                            ATGen cuenta con una planta de producción habilitada por el MSP, MGAP y certificación GMP MERCOSUR, para la fabricación de 
                            reactivos de diagnóstico. Además, tiene un sistema de gestión de calidad certificado bajo la norma ISO 13485.
                        </p>
                        <div className="text-center">
                            <img className="mw-33" src={certLSQA} alt="LSQA" />
                            <img className="mw-33" src={certGMP} alt="GMP" />
                            <img className="mw-33" src={certMSP} alt="MSP" />
                        </div>
                        <hr />
                        <h5 className="my-2">Gestión de calidad</h5>
                        <ul>
                            <li>
                                ATGen cuenta con un laboratorio clínico especializado en técnicas de biología molecular, el cual brinda servicios a 
                                instituciones de salud pública y privada, nacional y regional. El laboratorio cuenta con la correspondiente habilitación 
                                del MSP.
                            </li>
                            <li>
                                ATGen cuenta con una planta de producción habilitada por el MSP y certificación GMP MERCOSUR, para la fabricación de reactivos de 
                                diagnóstico. Además, tiene un sistema de gestión de calidad certificado bajo la norma ISO 13485.
                            </li>
                        </ul>
                        <hr />
                        <h5 className="my-2">Misión</h5>
                        <p>
                            <strong>Contribuir desde la biotecnología a promover la salud de las personas, los animales y el medioambiente.</strong>
                        </p>
                        <hr />
                        <h5 className="my-2">Visión</h5>
                        <p>
                            <strong>Posicionarnos como un referente biotecnológico a nivel global en la búsqueda de soluciones accesibles y de calidad, 
                                que repercutan favorablemente en la salud de las personas, los animales y el medioambiente.</strong>
                        </p>
                        <hr />
                        <h5 className="my-2">Política de calidad</h5>
                        <ul>
                            <li>
                                Cumplir con las buenas prácticas de manufactura como parte del aseguramiento de la calidad y seguridad de sus productos. Cumplir 
                                con la reglamentación vigente a nivel nacional y regional, en lo referente al desarrollo, elaboración y comercialización de materias 
                                primas y productos. Implementar y mantener un sistema de calidad ISO 13485. Cumplir con las buenas prácticas de laboratorio y conforme 
                                a la normativa nacional. 
                            </li>
                            <li>
                                Orientación al cliente, identificar sus necesidades y buscar soluciones de calidad que mejor se ajusten a su realidad operativa y presupuestal.
                            </li>
                            <li>
                                Fortalecer permanentemente el capital humano de la empresa en un ambiente de trabajo en equipo, basado en el respeto de los aportes individuales 
                                y en una cultura de prevención de riesgos laborales y respeto al medio ambiente.
                            </li>
                            <li>
                                Buscar relaciones perdurables con los proveedores, orientadas al crecimiento mutuo y al mantenimiento y a satisfacer las necesidades de 
                                nuestro clientes a nivel nacional y regional.
                            </li>
                            <li>
                                Asegurar el crecimiento sustentable de la empresa en un marco ético apoyado en su capital intelectual. Promover la internacionalización a 
                                través de nuevos vínculos comerciales y la incorporación continua de nuevas líneas de productos.
                            </li>
                            <li>
                                Potenciar la actividad innovadora de la empresa.
                            </li>
                        </ul>
                        <p>
                            ENTRADA EN VIGENCIA: 22/03/2019
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr/>
                        <div className="d-flex justify-content-center">
                            <BtnMenu />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default QuienesSomos;