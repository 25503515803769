import { Link } from 'react-router-dom';
import agendaAeropuerto from '../../assets/imgs/agenda_aeropuerto.png';
import { AGENDA_AEROPUERTO } from '../../routes';

const BtnAgendaAeropuerto = ({ irAntigenos }) => {
    return (
        <div className="btn-seccion">
            <Link to={`${AGENDA_AEROPUERTO}?antigenos`} className="d-flex flex-column align-items-center">
                <img src={agendaAeropuerto} alt="Agendar en Aeropuerto" />
                <div>Agendarme en Aeropuerto</div>
            </Link>
        </div>
    );
};

export default BtnAgendaAeropuerto;