import app_covid_uy from '../../assets/imgs/app_covid_uy.png';
import { APP_CORONAVIRUS_UY } from '../../routes';
import { Link } from 'react-router-dom';

const BtnDetalleApp = (props) => {
    return (
        <div className="btn-seccion">
            <Link to={APP_CORONAVIRUS_UY} className="d-flex flex-column align-items-center"> 
                <img src={app_covid_uy} alt="App Coronavirus UY" />
                {props.textoAgenda ? (
                    <div>Agendarme con el código QR que recibí</div>
                ) : (
                    <div>App Coronavirus UY</div>
                )}
            </Link>
        </div>
    );
};

export default BtnDetalleApp;