import { Link } from 'react-router-dom';
import agendaBlanca from '../../assets/imgs/agenda_blanca.png';
import { AGENDA } from '../../routes';

const BtnAgenda = () => {
    return (
        <div className="btn-seccion">
            <Link to={AGENDA} className="d-flex flex-column align-items-center">
                <img src={agendaBlanca} alt="Gestionar mi agenda" />
                <div>Agendar mi test</div>
            </Link>
        </div>
    );
};

export default BtnAgenda;