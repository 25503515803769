import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Zendesk from "react-zendesk";
import ATGenNavBar from "./components/organisms/navbar/index";
import Agenda from "./components/pages/agenda/index";
import AgendaAeropuerto from "./components/pages/agendaAeropuerto/index";
import AgendaAntigeno from "./components/pages/agendaAntigeno/index";
import AgendaCancelar from "./components/pages/agendaCancelar/index";
import AgendaModificar from "./components/pages/agendaModificar/index";
import AppCoronavirusUy from "./components/pages/appCoronavirusUy/index";
import Contacto from "./components/pages/contacto/index";
import Home from "./components/pages/home/index";
import InfoCovid from "./components/pages/infoCovid/index";
import LlegueDeViaje from "./components/pages/llegueDeViaje/index";
import MeVoyDeViaje from "./components/pages/meVoyDeViaje/index";
import MiResultado from "./components/pages/miResultado/index";
import QuienesSomos from "./components/pages/quienesSomos/index";
import ViajoEnBarco from "./components/pages/viajoEnBarco/index";
import Test7moDia from "./components/pages/test7moDia/index";
import {
  AGENDA,
  AGENDA_AEROPUERTO,
  AGENDA_ANTIGENO,
  AGENDA_CANCELAR,
  AGENDA_MODIFICAR,
  APP_CORONAVIRUS_UY,
  CONTACTO,
  HOME,
  INFO_COVID,
  LLEGUE_DE_VIAJE,
  ME_VOY_DE_VIAJE,
  MI_RESULTADO,
  QUIENES_SOMOS,
  VIAJO_EN_BARCO,
  TEST_7MO_DIA,
} from "./routes";

const App = () => {
  const ZENDESK_KEY = "e6dafe59-77a3-47be-aa00-95290cfd892d";
  let location = useLocation();

  const [actualLocation, setActualLocation] = useState(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!actualLocation) {
      setActualLocation(location);
    } else {
      if (actualLocation.pathname !== location.pathname) {
        setActualLocation(location);
      }
    }
  }, [location]);

  useEffect(() => {
    console.log(actualLocation, window.ga);
    if (actualLocation && window.ga) {
      window.ga('set', 'page', actualLocation.pathname);
      window.ga('send', 'pageview');
    }
  }, [actualLocation]);

  return (
    <>
      <ATGenNavBar />
      <Zendesk zendeskKey={ZENDESK_KEY} />
      <Switch>
        <Route exact path={HOME} component={Home} />
        <Route exact path={ME_VOY_DE_VIAJE} component={MeVoyDeViaje} />
        <Route exact path={LLEGUE_DE_VIAJE} component={LlegueDeViaje} />
        <Route exact path={VIAJO_EN_BARCO} component={ViajoEnBarco} />
        <Route exact path={MI_RESULTADO} component={MiResultado} />
        <Route exact path={AGENDA_AEROPUERTO} component={AgendaAeropuerto} />
        <Route exact path={AGENDA_CANCELAR} component={AgendaCancelar} />
        <Route exact path={AGENDA_MODIFICAR} component={AgendaModificar} />
        <Route exact path={AGENDA} component={Agenda} />
        <Route exact path={QUIENES_SOMOS} component={QuienesSomos} />
        <Route exact path={INFO_COVID} component={InfoCovid} />
        <Route exact path={CONTACTO} component={Contacto} />
        <Route exact path={APP_CORONAVIRUS_UY} component={AppCoronavirusUy} />
        <Route exact path={TEST_7MO_DIA} component={Test7moDia} />
      </Switch>
    </>
  );
};

export default App;
