import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

import avionAterrizando from "../../../assets/imgs/avion_aterrizando.png";
import BtnMenu from "../../molecules/btnMenu";
import BtnAgendaAutotest from "../../molecules/btnAgendaAutotest";
import BtnAgendaDomicilio from "../../molecules/btnAgendaDomicilio";

const LlegueDeViaje = () => {
  return (
    <Row className="flex-grow-1 mr-0 ml-0">
      <Col xs={12} className="p-0">
        <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
          <img
            className="img-encabezado mt-2"
            src={avionAterrizando}
            alt="Vuelvo de viaje"
          />
          <h3>Vuelvo de viaje</h3>
        </Jumbotron>
      </Col>
      <Container className="mt-3">
        <Row>
          <Col xs={12} md={6}>
            <h5 className="my-2">¿Qué debo hacer?</h5>
            <p>
              Si usted precisa coordinar un test para el ingreso al pais, 
              puede hacerlo comunicándose de forma previa al (+598) 2 600 6001.
            </p>
            
            <hr className="d-md-none" />
          </Col>
          <Col xs={12} md={6}>
            
            <p>
              
            </p>
            
            <div className="mt-4 d-flex justify-content-around">
              <BtnAgendaAutotest />
              <BtnAgendaDomicilio />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <div className="d-flex justify-content-center">
              <BtnMenu />
            </div>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default LlegueDeViaje;
