import menos from '../../assets/imgs/menos.png';

const BtnMenos = () => {
    return (
        <div className="btn-aux">
            <div className="d-flex flex-column align-items-center">
                <img src={menos} alt="Ocultar detalle" />
            </div>
        </div>
    );
};

export default BtnMenos;