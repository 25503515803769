import React, { useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";
import labAeropuerto from "../../../assets/imgs/aeropuerto.jpg";
import agendaAeropuerto from "../../../assets/imgs/agenda_aeropuerto.png";
import { AGENDA_AEROPUERTO_LINK } from "../../../routes";
import BtnAgendaAntigeno from "../../molecules/btnAgendaAntigeno";
import BtnMenu from "../../molecules/btnMenu";
import TablaVuelos from "../../molecules/tablaVuelos";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AgendaAeropuerto = () => {
  const refAntigeno = useRef(null);

  let query = useQuery();

  useEffect(() => {
    if (query.get("antigenos") !== null) {
      setTimeout(() => window.scrollTo(0, refAntigeno.current.offsetTop), 750);
    }
  }, [query, refAntigeno]);

  return (
    <Row className="flex-grow-1 mr-0 ml-0">
      <Col xs={12} className="p-0">
        <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
          <img
            className="img-encabezado mt-2"
            src={agendaAeropuerto}
            alt="Agenda aeropuerto"
          />
          <h3>Agenda aeropuerto</h3>
        </Jumbotron>
      </Col>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          


            <p className="mt-3">
              Para utilizar este servicio debe comunicarse de forma previa al (+598) 2 600 6001. Horario de atención telefónica 
              al público de Lunes a viernes de 8am a 18pm. 
            </p>
            <Image src={labAeropuerto} className="w-100 mb-3" rounded />
            
            <TablaVuelos urlVuelos="https://sheets.googleapis.com/v4/spreadsheets/1zobpS0cbSyWkKt2Q7o_prRwumZIrDaAoWprDlNEPlPI/?includeGridData=true&key=AIzaSyBBJEA8tIDHH8Q6Y_3w63Gl-nLHonitqr8" />
            
           
            <hr />

            <p>
              <strong>Test PCR:</strong>
            </p>
            <ul>
              <li>
                El costo del servicio es de $ 4.510 IVA y timbre incluido con
                tarjeta de débito o crédito.
              </li>
              <li>
                El resultado se envía por mail y WhatsApp previo al embarque en
                formato PDF en español e inglés.
              </li>
              <li>
                Realizamos hisopados nasofaríngeos únicamente en niños mayores
                de 1 año.
              </li>
            </ul>
            <div className="btn-seccion mb-3">
              <a
                href={AGENDA_AEROPUERTO_LINK}
                target={"_blank"}
                rel="noreferrer"
                className="d-flex flex-column align-items-center"
              >
                <img src={agendaAeropuerto} alt="Agenda Auto test" />
                <div>Agendarme en Aeropuerto</div>
              </a>
            </div>
            <hr />

            <p ref={refAntigeno}>
              <strong>Test Antígeno:</strong>
            </p>
          
            <ul>
               
            <li>
                
                El resultado se envía en español e inglés.
              </li>
              <li>
                El costo del servicio es de $ 2.510 IVA y timbre incluido con
                tarjeta de débito o crédito al momento de realizarse el estudio.
              </li>
              
              <li>
                Realizamos hisopados nasofaríngeos únicamente en niños mayores
                de 1 año.
              </li>
             </ul>
            
             <h5 className="my-2">
              <a  href={AGENDA_AEROPUERTO_LINK}
                target={"_blank"}
                rel="noreferrer"
                className="d-flex flex-column align-items-center">
              AGENDA WEB. 
              </a>
            </h5> 
                      

          </Col>
        </Row>
        
        <Row>
          <Col>
           
            <div className="d-flex justify-content-around">
              <BtnMenu />
            </div>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default AgendaAeropuerto;
