import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import hisopado from "../../assets/imgs/hisopado.svg";
import { AGENDA_AUTO_TEST } from "../../routes";
import Button from "react-bootstrap/Button";

const ModalNovedades = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Button className="btn-cerrar-modal" onClick={props.onHide}>
        &times;
      </Button>
      <Modal.Body className="modal-novedades modal-novedades-bg m-4 pb-0">
        <Row>
          <Col className="d-flex flex-column">
            <p className="text-center mt-4 mb-1">
              <span className="titulo-novedades-fiestas">
                <strong>¡NOVEDADES!</strong>
              </span>
            </p>

            <p className="text-center mt-0 mb-0"> 
            <hr />
            <p className="novedades text-center mt-1">
                <font color="red"><strong>AEROPUERTO</strong></font> de Lunes a Viernes previa coordinación (+598) 2 600 6001. 
                <br /><strong>Por más información<a href="http://covid19.atgen.com.uy/me-voy-de-viaje" target="blanck"> AQUÍ.</a></strong><br />
              </p>
            <hr />
            </p>
          
          <p className="novedades text-center mt-1">
          <font color="red"><strong>PANEL RESPIRATORIO POR PCR</strong></font><br /><strong>SARS-CoV-2 + Influenza tipo A + 
            <br />Influenza tipo B + VIRUS RESPIRATORIO SINCITIAL (VRS).</strong>
              <br />
               Detección simultánea para: SARS-CoV-2 (causante de COVID-19), virus influenza tipo A y
               tipo B (causantes de la gripe estacional) y virus respiratorio sincitial a partir de ÚNICA muestra de hisopado.
              
              <br></br><br></br>
             <ul>
              
             </ul>
               
            </p> 
         
            <div className="text-center mt-3">
              <img src={hisopado} width="140" alt="Logo ATGen" />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNovedades;
