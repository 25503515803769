import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import advertenciaTestDeAntigeno from "../../assets/ADVERTENCIA_TEST_DE_ANTIGENO.pdf";
import agendaBlanca from "../../assets/imgs/agenda_blanca.png";
import labAutotest from "../../assets/imgs/autotest.jpg";
import { AGENDA_AUTO_TEST, AGENDA_ANTIGENO_GX } from "../../routes";
import BtnAgendaAntigeno from "./btnAgendaAntigeno";
import BtnMas from "./btnMas";
import ModalMapaAutotestMaldonado from "./modalMapaAutotestMaldonado";
import ModalMapaAutotestMontevideo from "./modalMapaAutotestMontevideo";
import ModalMapaAutotestParqueRodo from "./modalMapaAutotestParqueRodo";

const BtnAgendaAutotest = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showModalMapaAeropuerto, setShowModalMapaAeropuerto] = useState(false);
  const handleCloseModalMapaMontevideo = () => setShowModalMapaAeropuerto(false);
  const handleShowModalMapaMontevideo = () => setShowModalMapaAeropuerto(true);

  const [showModalMapaMaldonado, setShowModalMapaMaldonado] = useState(false);
  const handleCloseModalMapaMaldonado = () => setShowModalMapaMaldonado(false);
  const handleShowModalMapaMaldonado = () => setShowModalMapaMaldonado(true);

  const [showModalMapaParqueRodo, setShowModalMapaParqueRodo] = useState(false);
  const handleCloseModalMapaParqueRodo = () => setShowModalMapaParqueRodo(false);
  const handleShowModalMapaParqueRodo = () => setShowModalMapaParqueRodo(true);

  return (
    <>
      <div className="btn-seccion">
        <a
          href="#"
          onClick={handleShowModal}
          className="d-flex flex-column align-items-center"
        >
          <img src={agendaBlanca} alt="Agenda Auto test" />
          <div>Agendarme</div>
        </a>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-atgen">
            Acerca del Autotest
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Image src={labAutotest} className="w-100 mb-2" rounded />
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="0"
                      className="w-100"
                    >
                      <div className="d-flex justify-content-between align-items-center pointer">
                        <h5 className="mb-0 mr-2">Test PCR</h5>
                        <BtnMas />
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        <strong>
                          Para que sirve PCR (hisopado nasofaríngeo):
                        </strong>
                      </p>
                      <p>Diagnóstico de SARS-CoV-2.</p>
                      <p>
                        Se recomienda su realización a contactos estrechos y/o
                        personas con síntomas compatibles con COVID-19.
                      </p>
                      <p>
                        <strong>
                        Ubicado en Av. Italia 6201 - Edificio Los Tilos - 
                        <br/>Of. 105 dentro de Parque tecnológico LATU. 
                        </strong>
                        <br/>
                         Con estacionamiento disponible
                      </p>
                      <p>
                      Atención de Lunes a Viernes con agenda previa por la web, teléfono y whatsapp al <b>26006001</b> ó mail: <b>coordinaciones@atgen.com.uy</b> 
                      </p>
                      <p>
                        <strong>Resultados en el día.</strong> 
                        <br/>Precio test PCR <b>$3.510 IVA</b> y timbre incluido con tarjeta de débito o crédito. Consultar por convenios.
                      </p>
                      
                      {/*<ul>
                        <li>
                        <font color="red">AUTOTEST MONTEVIDEO LATU:</font> Ubicado en Av. Dra. María Saldún de
                          Rodríguez 2037 esquina Bolonia - Centro de Eventos del
                          LATU{" "}
                          <Button
                            className="p-0 btn-donde-bqb"
                            onClick={handleShowModalMapaMontevideo}
                            variant="link"
                            size="sm"
                          >
                            (Ver en mapa)
                          </Button>
                          Atención de Lunes a Viernes con agenda previa por la web, 
                          teléfono y whatsapp al 26006001 ó mail: coordinaciones@atgen.com.uy.  
                          Resultados en el día. Precio test PCR $3.510 IVA y timbre incluido con 
                          tarjeta de débito o crédito. Consultar por convenios.
                          {/*.Horario para PCR de Lunes a Viernes de 8am a 14pm y Sábado de 8 a 12 hs con resultado en el día. Precio test
                          PCR $3.510 IVA y timbre incluido con tarjeta de débito o crédito. Resultados en el día. */}
                        {/*</li>

                         {/*                                            
                        <li>
                        <font color="red">AUTOTEST MALDONADO:</font> Ubicado en el Parking del Hotel
                          Enjoy, entrada por Blvr. Artigas entre Biarritz y
                          Chiverta{" "}
                          <Button
                            className="p-0 btn-donde-bqb"
                            onClick={handleShowModalMapaMaldonado}
                            variant="link"
                            size="sm"
                          >
                            (Ver en mapa)
                          </Button>
                          . Atención de Lunes a Sábados con agenda previa por teléfono y 
                          whatsapp al 26006001 ó vía mail: coordinaciones@atgen.com.uy {/*Horario de Lunes a Viernes de 8am a 13pm. 
                          Resultados en plazo menor a 24 hs.
                          Precio test PCR $3.510 IVA y timbre incluido con tarjeta de débito o crédito.  Resultados en el día. */}
                        {/*</li>*/}  
                        
                        {/* <li>
                        <font color="red">AUTOTEST SOLANAS:</font> RUTA INTERBALNEARIA KM. 118 (ROTONDA RUTA 12) Del portezuelo y Juana de América.
                          Al lado de ferreteria La Ballena. Frente a la rotonda de Solanas-Punta Ballena.
                                            {" "}
                         <Button
                            className="p-0 btn-donde-bqb"
                            onClick={handleShowModalMapaMaldonado}
                            variant="link"
                            size="sm"
                          >
                            (Ver en mapa)
                          </Button> 
                          . Horario de Lunes a Domingo de 9 a 13 hs.
                          Resultados en el día. 
                        </li> */}

                     {/* </ul>*/} 
                      <p>
                        <strong>Política de cancelación:</strong> Usted tiene
                        derecho a revocar la presente contratación, sin
                        responsabilidad alguna, debiendo para ello comunicarlo
                        en un plazo de hasta 24 horas antes de la hora agendada.
                        Vencido dicho plazo, la revocación generará un costo
                        equivalente al 100 % del monto de los servicios
                        contratados. El reintegro se realizará por la misma vía
                        por la que se efectuó el pago. El tiempo que demore en
                        acreditarse el reembolso depende de cada tarjeta y/o
                        banco emisor que opere en cada caso. Si Ud. ha
                        modificado su fecha y hora de agenda, se tomará en
                        cuenta la fecha y hora de la reserva original para
                        determinar si se cumple con el criterio de cancelación.
                      </p>
                      <div className="btn-seccion d-flex">
                        <a
                          href={AGENDA_AUTO_TEST}
                          target={"_blank"}
                          rel="noreferrer"
                          className="d-flex flex-column align-items-center flex-grow-1"
                        >
                          <img src={agendaBlanca} alt="Agenda Auto test PCR" />
                          <div>Agendarme para PCR</div>
                        </a>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      className="w-100"
                    >
                      <div className="d-flex justify-content-between align-items-center pointer">
                        <h5 className="mb-0 mr-2">Test ANTÍGENO</h5>
                        <BtnMas />
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      
                      <p>
                        <strong>Para que sirve test de antígenos:</strong>
                      </p>
                      <p>Diagnóstico de SARS-CoV-2 (hisopado nasofaríngeo)</p>

                      <p>
                        <strong>
                        Ubicado en Av. Italia 6201 - Edificio Los Tilos - 
                        <br/>Of. 105 dentro de Parque tecnológico LATU. 
                        </strong>
                        <br/>
                         Con estacionamiento disponible
                      </p>
                      <p>
                      Atención de Lunes a Viernes con agenda previa por la web, teléfono y whatsapp al <b>26006001</b> ó mail: <b>coordinaciones@atgen.com.uy</b> 
                      </p>
                      <p>
                        <strong>Resultados en el día.</strong> 
                        <br/>Precio test PCR <b>$1.210 IVA</b> y timbre incluido con tarjeta de débito o crédito. Resultados por mail en plazo menor a 2 horas.
                      </p>

                    {/*}
                      <p>
                        <strong>
                          Ubicación, horario y precio del test ANTIGENO en
                          AUTOTEST:
                        </strong>
                      </p>
                      
                      <ul>
                        <li>
                        <font color="red">AUTOTEST MONTEVIDEO LATU:</font>Ubicado en Av. Saldún de
                          Rodríguez 2037 esquina Bolonia - Centro de Eventos del
                          LATU{" "}
                          <Button
                            className="p-0 btn-donde-bqb"
                            onClick={handleShowModalMapaMontevideo}
                            variant="link"
                            size="sm"
                          >
                            (Ver en mapa)
                          </Button>
                          Atención de Lunes a Viernes con agenda previa por la web, 
                          teléfono y whatsapp al 26006001 ó mail: coordinaciones@atgen.com.uy. 
                          Precio test $1.210 IVA y timbre incluido con tarjeta 
                          de débito o crédito. Resultados por mail en plazo 
                          menor a 2 horas.
                          
                        </li>
                                             

                        <li>
                        <font color="red">AUTOTEST MALDONADO:</font> Ubicado en el Parking del Hotel
                          Enjoy, entrada por Blvr. Artigas entre Biarritz y
                          Chiverta{" "}
                          <Button
                            className="p-0 btn-donde-bqb"
                            onClick={handleShowModalMapaMaldonado}
                            variant="link"
                            size="sm"
                          >
                            (Ver en mapa)
                          </Button>
                          . Atención de Lunes a Sábados con agenda previa 
                          vía teléfono y whatsapp al 26006001 ó vía mail: coordinaciones@atgen.com.uy . }
                          Precio test $1.210 IVA y timbre incluido con tarjeta de débito o crédito. Resultados por mail en plazo menor a 2 horas.
                        </li>


                      </ul>
                    */}

                      <p>
                        <strong>Política de cancelación:</strong> Usted tiene
                        derecho a revocar la presente contratación, sin
                        responsabilidad alguna, debiendo para ello comunicarlo
                        en un plazo de hasta 24 horas antes de la hora agendada.
                        Vencido dicho plazo, la revocación generará un costo
                        equivalente al 100 % del monto de los servicios
                        contratados. El reintegro se realizará por la misma vía
                        por la que se efectuó el pago. El tiempo que demore en
                        acreditarse el reembolso depende de cada tarjeta y/o
                        banco emisor que opere en cada caso. Si Ud. ha
                        modificado su fecha y hora de agenda, se tomará en
                        cuenta la fecha y hora de la reserva original para
                        determinar si se cumple con el criterio de cancelación.
                      </p>
                      <div className="btn-seccion d-flex">
                        <a
                          href={AGENDA_ANTIGENO_GX}
                          target={"_blank"}
                          rel="noreferrer"
                          className="d-flex flex-column align-items-center flex-grow-1"
                        >
                          <img src={agendaBlanca} alt="Agenda para test de antígeno" />
                          <div>Agendarme para Antígeno</div>
                        </a>
                      </div>
                      <p className="mt-3 mb-0">
                        Nota:{" "}
                        <a
                          className="text-red"
                          href={advertenciaTestDeAntigeno}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Información importante sobre Test Antígeno
                        </a>
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="2"
                      className="w-100"
                    >
                      <div className="d-flex justify-content-between align-items-center pointer">
                        <h5 className="mb-0 mr-2">Test ANTICUERPOS</h5>
                        <BtnMas />
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        <strong>Para que sirve test de anticuerpos:</strong>
                      </p>
                      <p>
                        Se recomienda para detectar la presencia de anticuerpos
                        en sangre contra SARS-CoV-2 o para evaluar la respuesta
                        inmunitaria frente a las vacunas de COVID-19.
                      </p>
                      <ul>
                        <li>
                        Deteccion cuantitativa de anticuerpos IgG dirigidos
                         contra la proteina S (Spike) del SARS-CoV-2
                        </li>
                        <li>
                        Tecnica utilizada: ELISA.
                        </li>
                        <li>
                        A diferencia de la cualitativa, se informa un valor (cantidad) 
                        de anticuerpos IgG presentes en sangre, expresado en unidades internacionales.
                        </li>
                        <li>
                        Dearrollado junto con la UdelaR y el Institut Pasteur de Montevideo.
                        </li>
                        <li>
                        Tipo de muestra: sangre obtenida por punción venosa.
                        </li>
                      </ul>
                      <p>
                        Precio del estudio $2.110 IVA y timbre incluidos. 
                        {" "}
                        {/*<Button
                          className="p-0 btn-donde-bqb"
                          onClick={handleShowModalMapaParqueRodo}
                          variant="link"
                          size="sm"
                        >
                          (Ver en mapa) 
                        </Button>*/}
                        
                      </p>
                      <p><strong>Indicaciones para prueba Anticuerpos:</strong></p>
                      <ul>
                        {/*<li>
                          Para la toma de muestra debe descender del vehículo.
                        </li>*/}
                        <li>Se requiere un mínimo de 4 horas de ayuno.</li>
                        <li>
                          No debe asistir si se encuentra cursando síntomas
                          compatibles con COVID-19.
                        </li>
                      </ul>
                       <p>El resultado se envía por mail en un plazo máximo de 48 horas.</p>
                                        
                      
                      <p>
                       <font color="red">
                        <strong>Podrás agendar tu estudio para asisitir a nuestro
                          laboratorio o solicitarlo a domicilio escribiendo a coordinaciones@atgen.com.uy
                          o por whatsapp al 2600 6001 ó097 329 487. También contamos con opción de toma de muestra
                          en el interior. 
                        </strong>
                       </font>   
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalMapaAutotestMontevideo
        show={showModalMapaAeropuerto}
        onHide={handleCloseModalMapaMontevideo}
      />
      <ModalMapaAutotestMaldonado
        show={showModalMapaMaldonado}
        onHide={handleCloseModalMapaMaldonado}
      />
      <ModalMapaAutotestParqueRodo
        show={showModalMapaParqueRodo}
        onHide={handleCloseModalMapaParqueRodo}
      />
    </>
  );
};

export default BtnAgendaAutotest;
