import Axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";

const TablaVuelos = ({ urlVuelos }) => {
  const [vuelos, setVuelos] = useState([]);
  const [vuelosFiltrados, setVuelosFiltrados] = useState([]);
  const [loadingVuelos, setLoadingVuelos] = useState(false);
  const [filtroVuelos, setFiltroVuelos] = useState("");
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getVuelos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filtrarVuelos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroVuelos, vuelos.length]);

  const getVuelos = async () => {
    try {
      setLoadingVuelos(true);
      const request = Axios.create({
        baseURL: urlVuelos,
      });
      const response = await request.get();
      if (response.status === 200) {
        const rows = response.data.sheets[0].data[0].rowData;
        setVuelos(rows);
        setLoadingVuelos(false);
      } else {
        console.log(response);
        setTimeout(async () => {
          await getVuelos();
        }, 10000);
      }
    } catch {}
  };

  const filtrarVuelos = (offset) => {
    offset = offset ? offset : 0;
    let tempVuelos = [];
    if (!filtroVuelos) {
      tempVuelos = vuelos.filter((row, ix) => {
        if (ix === 0 || !row.hasOwnProperty("values")) {
          return false;
        }
        return row.values[1].formattedValue;
      });
    } else {
      tempVuelos = vuelos.filter((row, ix) => {
        if (ix === 0 || !row.hasOwnProperty("values")) {
          return false;
        }
        return (
          row.values[1].formattedValue &&
          row.values[1].formattedValue
            .toString()
            .toLowerCase()
            .includes(filtroVuelos.toString().toLowerCase())
        );
      });
    }
    setPageCount(Math.ceil(tempVuelos.length / 10));
    setVuelosFiltrados(tempVuelos.splice(offset, 10));
  };

  const onPageChange = (data) => {
    const offset = Math.ceil(data.selected * 10);
    filtrarVuelos(offset);
  };

  const getCellAlignment = (format) => {
    let alignment = "text-left";
    if (!format) {
      return alignment;
    }
    // eslint-disable-next-line default-case
    switch (format.horizontalAlignment) {
      case "CENTER":
        alignment = "text-center";
        break;
      case "RIGHT":
        alignment = "text-right";
        break;
    }
    return alignment;
  };

  const getVuelosHeaders = () => {
    return vuelos[0].values.map((c, ix) => {
      const alignment = getCellAlignment(c.effectiveFormat);
      return (
        <th className={alignment} key={ix}>
          {c.formattedValue}
        </th>
      );
    });
  };

  const getVuelosRows = () => {
    return vuelosFiltrados.map((r, ixR) => {
      return (
        <tr key={ixR}>
          {vuelosFiltrados[ixR].values.map((c, ix) => {
            const alignment = getCellAlignment(c.effectiveFormat);
            return (
              <td className={alignment} key={ix}>
                {c.formattedValue}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <>
      {loadingVuelos ? (
        <div className="cargando-vuelos d-flex justify-content-center align-items-center">
          <div className="loader mx-2"></div>
          <h5 className="d-inline m-0 mx-2">Cargando vuelos</h5>
        </div>
      ) : (
        <>
          {vuelos.length > 1 ? (
            <>
              <input
                className="form-control form-control-sm"
                placeholder="Filtre por su número de vuelo"
                type="text"
                value={filtroVuelos}
                onChange={(e) => setFiltroVuelos(e.target.value)}
              />
              <Table
                className="tabla-vuelos mb-0"
                responsive
                striped
                bordered
                size="sm"
              >
                <thead>
                  <tr>{getVuelosHeaders()}</tr>
                </thead>
                <tbody>{getVuelosRows()}</tbody>
              </Table>
              {vuelosFiltrados.length === 1 ? (
                <div className="cargando-vuelos d-flex justify-content-center align-items-center">
                  <p className="d-inline m-0 mx-2">
                    Ningún vuelo coincide con el filtro ingresado
                  </p>
                </div>
              ) : (
                <nav aria-label="Paginador de vuelos">
                  <ReactPaginate
                    previousLabel={<strong>&lt;</strong>}
                    nextLabel={<strong>&gt;</strong>}
                    activeClassName={"item active "}
                    containerClassName={
                      "pagination justify-content-center mt-2"
                    }
                    disabledClassName={"disabled-page"}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    onPageChange={onPageChange}
                    subContainerClassName={"pages pagination"}
                    breakClassName="page-item"
                    breakLabel={
                      <a href="#" className="page-link">
                        ...
                      </a>
                    }
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                  />
                </nav>
              )}
            </>
          ) : (
            <div className="cargando-vuelos d-flex justify-content-center align-items-center">
              <h5 className="d-inline m-0 mx-2">No se encontraron vuelos</h5>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TablaVuelos;
