import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import barcoBlanco from "../../../assets/imgs/barco_blanco.png";
import pasajeBqb from "../../../assets/imgs/BQB.png";
import popupHorariosAutotest from "../../../assets/imgs/popupHorarios.png";
import BtnMenu from "../../molecules/btnMenu";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useState } from "react";
import * as yup from "yup";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Formik, ErrorMessage } from "formik";
import ModalMapaAutotestMontevideo from "../../molecules/modalMapaAutotestMontevideo";
import ModalMapaAutotestMaldonado from "../../molecules/modalMapaAutotestMaldonado";
import ModalHorarios from "../../molecules/modalHorarios";
import moment from "moment";

const ViajoEnBarco = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showSubmitOk, setShowSubmitOk] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [opcionesLugar, setOpcionesLugar] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showModalMapaAeropuerto, setShowModalMapaAeropuerto] = useState(false);
  const handleCloseModalMapaMontevideo = () =>
    setShowModalMapaAeropuerto(false);
  const handleShowModalMapaMontevideo = () => setShowModalMapaAeropuerto(true);

  const [showModalMapaMaldonado, setShowModalMapaMaldonado] = useState(false);
  const handleCloseModalMapaMaldonado = () => setShowModalMapaMaldonado(false);
  const handleShowModalMapaMaldonado = () => setShowModalMapaMaldonado(true);

  const [showModalHorarios, setShowModalHorarios] = useState(false);
  const handleCloseModalHorarios = () => setShowModalHorarios(false);
  const handleShowModalHorarios = () => setShowModalHorarios(true);

  const formInitialValues = {
    primer_apellido: "",
    segundo_apellido: "",
    nombres: "",
    telefono: "",
    email: "",
    nro_doc: "",
    fecha: "",
    lugar: "",
    cod_pasaje: "",
  };

  const formValidationSchema = yup.object().shape({
    primer_apellido: yup.string().required("Debe ingresar su primer apellido."),
    segundo_apellido: yup.string().required("Debe ingresar su segundo apellido."),
    nombres: yup.string().required("Debe ingresar sus nombres."),
    telefono: yup.string().required("Debe ingresar su teléfono."),
    email: yup.string().email("Debe ingresar un email válido.").required("Debe ingresar su email."),
    nro_doc: yup.string().required("Debe ingresar el número de documento."),
    fecha: yup.date().required("Debe ingresar la fecha en la que viaja."),
    lugar: yup.string().required("Debe seleccionar el luegar y la fecha para realizar el test."),
    cod_pasaje: yup.string().required("Debe ingresar el código de su pasaje."),
  });

  const formSubmit = async (values, { setSubmitting, resetForm }) => {
    setShowSubmitError(false);
    setShowSubmitOk(false);
    setSubmitting(true);
    const token = await executeRecaptcha("agenda_bqb");

    var form = new FormData();
    form.append("primer_apellido", values.primer_apellido);
    form.append("segundo_apellido", values.segundo_apellido);
    form.append("nombres", values.nombres);
    form.append("telefono", values.telefono);
    form.append("email", values.email);
    form.append("nro_doc", values.nro_doc);
    form.append("fecha", values.fecha);
    form.append("lugar", values.lugar);
    form.append("cod_pasaje", values.cod_pasaje);
    form.append("token", token);

    const response = await axios({
      method: "post",
      url: "//covid19.atgen.com.uy/email/mail_agenda_bqb.php",
      data: form,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });

    if (response.status === 200) {
      setShowSubmitOk(true);
      resetForm();
      setSubmitting(false);
    } else {
      setShowSubmitError(true);
      setSubmitting(false);
      console.error("There was an error!", response);
    }
  };

  const handleFechaChange = (e, handleChange) => {
    handleChange(e);
    buildLugares(e.currentTarget.value);
  };

  const buildLugares = (f) => {
    const fecha = moment(f);
    if (fecha.isValid()) {
      const fecha24 = fecha.add(-1, "days").format("DD/MM/YYYY");
      const fecha48 = fecha.add(-1, "days").format("DD/MM/YYYY");
      let opciones = [];
      opciones.push(`Autotest Latu  -${fecha24}`);
      opciones.push(`Autotest Latu  -${fecha48}`);
      opciones.push(`Autotest Parque Rodó DOMINGOS CERRADOS - ${fecha24}`);
      opciones.push(`Autotest Parque Rodó - ${fecha48}`);
      opciones.push(`Autotest Enjoy - ${fecha24}`);
      opciones.push(`Autotest Enjoy - ${fecha48}`);
      opciones.push(`Autotest Colonia DOMINGOS CERRADOS - ${fecha24}`);
      opciones.push(`Autotest Colonia - ${fecha48}`);
      setOpcionesLugar(opciones);
    } else {
      setOpcionesLugar([]);
    }
  };

 return (
    <>
      <Row className="flex-grow-1 mr-0 ml-0">
        <Col xs={12} className="p-0">
          <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
            <img
              className="img-encabezado mt-2"
              src={barcoBlanco}
              alt="Viajo por Buquebus"
            />
            <h3>Viajo en barco</h3>
          </Jumbotron>
        </Col>
        <Container className="mt-3">
          <Row>
            
            <Col xs={12} md={12} className="p-0">
              <Formik initialValues={formInitialValues}
                   validationSchema={formValidationSchema} 
                   onSubmit={formSubmit}>
                      {({
                         values, 
                         errors, 
                         touched, 
                         handleChange,
                         handleBlur, 
                         handleSubmit, 
                         isSubmitting 
                        }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto">
                    <Row>
                      <Col xs={12}>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
        </Row>

        {/*
                <p>
                  <h5><strong>Ubicación y horarios:</strong></h5>
                </p>
                
              
              <ul>
               
                                       
                <li>
                  <strong>AUTOTEST MONTEVIDEO LATU:</strong> 
                   Ubicado en Av. Saldún de Rodríguez 2037 esquina 
                   Bolonia - Centro de Eventos del LATU.<br />
                   Atención de lunes a sábado con agenda previa.
                </li>
                    
                
                    
                <li>
                  <strong>AUTOTEST MALDONADO:</strong>
                   Ubicado en el Parking del Hotel Enjoy, 
                   entrada por Blvr. Artigas entre Biarritz y Chiverta.<br />
                   Atención de lunes a sábado con agenda previa. 
                </li>
              </ul>
              */}

                  <p className="texto">
                    <strong>Precios bonificados para pasajeros de cruceros.</strong>
                  </p>
                 

                  
                  <h5 className="my-2">
                 
                   <font color="red">
                     <strong>
                     &nbsp;Test RT-PCR $2.800
                     </strong> 
                   </font> 
                   &nbsp;IVA y timbre incluido. Resultados en el día.<br />
                   <h5 className="my-0">
                  <font size="3">*Excepto dias domingos, que el plazo de resultados es 24 horas.</font>
                  </h5><br />
                   <font color="red">
                     <strong>
                     &nbsp;Test de antígeno $850
                     </strong> 
                   </font> 
                   &nbsp;IVA y timbre incluido. Resultados en 2 horas.<br />
                   {/* <h5 className="my-0">
                  <font size="2">*.</font>
                  </h5> */}
                   <br />Para agendarse haga click <a href="https://www.atgendaweb.com/buque" target="_blank">AQUI.</a> 
                     {/* <br></br><br></br> Presentarse con el pasaje o comprobante impreso. */}
                  </h5>
                  <h5 className="my-0">
                  <font size="3">*Presentarse con acreditación de viaje.</font>
                  </h5><br />
                  
                 
                  
                  <hr />
                  <h5 className="my-2">
                  Por mas información comunicarse al<br />
                  2600 6001 ó enviar un mail a&nbsp;                  
                  <a href="mailto:covid19@atgen.com.uy?Subject=Mas%20información">covid19@atgen.com.uy</a> 
                  </h5>
          
      <Row>
            <Col>
              <hr />
              <div className="d-flex justify-content-center">
                <BtnMenu />
              </div>
            </Col>
          </Row>
        </Container>
      </Row> 


      
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-atgen">
            ¿Dónde encuentro el código?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className="w-100" src={pasajeBqb} alt="Pasaje Buquebus" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      
     <ModalMapaAutotestMontevideo
        show={showModalMapaAeropuerto}
        onHide={handleCloseModalMapaMontevideo}
      />
      <ModalMapaAutotestMaldonado
        show={showModalMapaMaldonado}
        onHide={handleCloseModalMapaMaldonado}
      />
      <ModalHorarios
        show={showModalHorarios}
        onHide={handleCloseModalHorarios}
      />
    </>
  );
};

export default ViajoEnBarco;
