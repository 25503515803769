import qr from '../../assets/imgs/qr.png';
import app_covid_uy from '../../assets/imgs/app_covid_uy.png';
import { AGENDA_QR } from '../../routes';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import ModalMapaAutotestMontevideo from './modalMapaAutotestMontevideo';
import ModalMapaAutotestMaldonado from './modalMapaAutotestMaldonado';

const BtnAgendaQR = (props) => {
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const [showModalMapaAeropuerto, setShowModalMapaAeropuerto] = useState(false);
    const handleCloseModalMapaMontevideo = () => setShowModalMapaAeropuerto(false);
    const handleShowModalMapaMontevideo = () => setShowModalMapaAeropuerto(true);

    const [showModalMapaMaldonado, setShowModalMapaMaldonado] = useState(false);
    const handleCloseModalMapaMaldonado = () => setShowModalMapaMaldonado(false);
    const handleShowModalMapaMaldonado = () => setShowModalMapaMaldonado(true);

    return (
        <>
            <div className="btn-seccion">
                <a href="#" onClick={handleShowModal} className="d-flex flex-column align-items-center"> 
                    <img src={props.iconoApp ? app_covid_uy : qr} alt="Qgenda QR" />
                    <div>Agendarme usando el código QR que recibí</div>
                </a>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title-atgen">Acerca del test con el QR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>
                                Para realizarte el test utilizando el código QR de la aplicación CORONAVIRUS UY se debe solicitar día y hora previamente. 
                            </p>
                            <p>
                                Contamos con dos AUTOTESTS, tanto en Montevideo como Maldonado. 
                            </p>
                            <ul>
                                <li>
                                    AUTOTEST MONTEVIDEO: Ubicado en Av. Saldún de Rodríguez 2037 esquina Bolonia - Centro de Eventos del LATU <Button className="p-0 btn-donde-bqb" onClick={handleShowModalMapaMontevideo} variant="link" size="sm">(Ver en mapa)</Button>. Horario de lunes a sábado de 8am a 14pm.
                                </li>
                                <li>
                                    AUTOTEST MALDONADO: 
                                    Ubicado en el Parking del Hotel Enjoy, entrada por Blvr. Artigas entre Biarritz y Chiverta 
                                    <Button className="p-0 btn-donde-bqb" onClick={handleShowModalMapaMaldonado} variant="link" size="sm">(Ver en mapa)</Button>.
                                     Horario de lunes a sábados 8am a 13pm. 
                                </li>
                            </ul>
                            <p>
                                Únicamente los pacientes previamente agendados serán atendidos, presentando el código QR junto con el documento de identidad
                            </p>
                            <div className="btn-seccion">
                                <a href={AGENDA_QR} target={'_blank'} rel='noreferrer' className="d-flex flex-column align-items-center"> 
                                    <img src={props.iconoApp ? app_covid_uy : qr} alt="Agenda QR" />
                                    <div>Agendarme usando el código QR que recibí</div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalMapaAutotestMontevideo show={showModalMapaAeropuerto} onHide={handleCloseModalMapaMontevideo} />
            <ModalMapaAutotestMaldonado show={showModalMapaMaldonado} onHide={handleCloseModalMapaMaldonado} />
        </>
    );
};

export default BtnAgendaQR;