import agendaBlanca from "../../assets/imgs/agenda_blanca.png";
import { AGENDA_DOMICILIO } from "../../routes";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image"; // eslint-disable-next-line
import advertenciaTestDeAntigeno from "../../assets/ADVERTENCIA_TEST_DE_ANTIGENO.pdf"; // eslint-disable-next-line
import labAutotest from "../../assets/imgs/autotest.jpg"; // eslint-disable-next-line
import { AGENDA_AUTO_TEST } from "../../routes"; // eslint-disable-next-line
import BtnAgendaAntigeno from "./btnAgendaAntigeno"; // eslint-disable-next-line
import BtnMas from "./btnMas";
import ModalMapaAutotestMaldonado from "./modalMapaAutotestMaldonado"; // eslint-disable-next-line
import ModalMapaAutotestMontevideo from "./modalMapaAutotestMontevideo"; // eslint-disable-next-line
import ModalMapaAutotestParqueRodo from "./modalMapaAutotestParqueRodo"; // eslint-disable-next-line
import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import moment from "moment"; // eslint-disable-next-line
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert"; // eslint-disable-next-line
import Form from "react-bootstrap/Form"; // eslint-disable-next-line
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";

const BtnAgendaDomicilio = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showSubmitOk, setShowSubmitOk] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const formInitialValues = {
    apellido: "",
    nombres: "",
    telefono: "",
    email: "",
    lugar_solicitado: "",
    fecha_solicitada: "",
    comentarios: "",
  };

  const formValidationSchema = yup.object().shape({
    apellido: yup.string().required("Debe ingresar sus apellidos."),
    nombres: yup.string().required("Debe ingresar sus nombres."),
    telefono: yup.string().required("Debe ingresar su teléfono o celular."),
    email: yup
      .string()
      .email("Debe ingresar un email válido.")
      .required("Debe ingresar su email."),
    lugar_solicitado: yup
      .string()
      .required("Debe seleccionar el lugar solicitado para realizar el test."),
    fecha_solicitada: yup
      .date()
      .required("Debe ingresar la fecha en la que desea realizar su test."),
    comentarios: yup.string(),
  });

  const formSubmit = async (values, { setSubmitting, resetForm }) => {
    setShowSubmitError(false);
    setShowSubmitOk(false);
    setSubmitting(true);
    const token = await executeRecaptcha("agenda_puestos_fronterizos");

    var form = new FormData();
    form.append("apellido", values.apellido);
    form.append("nombres", values.nombres);
    form.append("telefono", values.telefono);
    form.append("email", values.email);
    form.append("lugar_solicitado", values.lugar_solicitado);
    form.append("fecha_solicitada", values.fecha_solicitada);
    form.append("comentarios", values.comentarios);
    form.append("token", token);

    const response = await axios({
      method: "post",
      url: "//covid19.atgen.com.uy/email/mail_agenda_puestos_fronterizos.php",
      data: form,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });

    if (response.status === 200) {
      setShowSubmitOk(true);
      resetForm();
      setSubmitting(false);
    } else {
      setShowSubmitError(true);
      setSubmitting(false);
      console.error("There was an error!", response);
    }
  };

  const handleFechaChange = (e, handleChange) => {
    handleChange(e);
  };

  return (
    <>
      <div className="btn-seccion">
        <a
          href="#"
          onClick={handleShowModal}
          className="d-flex flex-column align-items-center"
        >
          <img src={agendaBlanca} alt="Me voy de viaje" />
          <div>Agendarme en domicilio</div>
          <div className="copa">Montevideo e Interior.</div>
        </a>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-atgen">
            Acerca del test a domicilio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="0"
                      className="w-100"
                    >
                      <div className="d-flex justify-content-between align-items-center pointer">
                        <h5 className="mb-0 mr-2">
                          Domicilio en Montevideo, <br/>Ciudad de la Costa.
                        </h5>
                        <BtnMas />
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        Contamos con sistema de toma de muestra a domicilio en
                        Montevideo y Ciudad de la Costa.
                      </p>
                      <ul>
                        <li>Precio $4.730 (IVA y timbre incluido)</li>
                        <li>Agenda web, pago mediante tarjeta de débito o crédito.</li>
                        <li>Tambien podes agendarte vía telefonica al (+598) 2 600 6001 ó a través de nuestro mail
                          coordinaciones@atgen.com.uy</li>
                        <li>
                          Los test a domicilio se realizan de lunes a sábado.
                        </li>
                        <li>
                          Resultado en 24 horas. Se enviará al correo
                          electrónico ingresado.
                        </li>
                        <li>
                          Realizamos hisopados nasofaríngeos únicamente en niños
                          mayores de 1 año.
                        </li>
                      </ul>
                      <p>
                        Política de cancelación: Usted tiene derecho a revocar
                        la presente contratación, sin responsabilidad alguna,
                        debiendo para ello comunicarlo en un plazo de hasta 24
                        horas antes de la hora agendada. Vencido dicho plazo, la
                        revocación generará un costo equivalente al 100 % del
                        monto de los servicios contratados. El reintegro se
                        realizará por la misma vía por la que se efectuó el
                        pago. El tiempo que demore en acreditarse el reembolso
                        depende de cada tarjeta y/o Banco emisor que opere en
                        cada caso. Si Ud. ha modificado su fecha y hora de
                        agenda, se tomará en cuenta la fecha y hora de la
                        reserva original para determinar si se cumple con el
                        criterio de cancelación.
                      </p>
                      <div className="btn-seccion">
                        <a
                          href={AGENDA_DOMICILIO}
                          target={"_blank"}
                          rel="noreferrer"
                          className="d-flex flex-column align-items-center"
                        >
                          <img src={agendaBlanca} alt="Agenda domicilio" />
                          <div>Agendarme en domicilio</div>
                        </a>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      className="w-100"
                    >
                      <div className="d-flex justify-content-between align-items-center pointer">
                        <h5 className="mb-0 mr-2">Domicilio en Maldonado</h5>
                        <BtnMas />
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      {/* <p></p> */}
                      {/* <p>a{" "}<a href="mailto:"></a></p> */}
                      <ul>
                        <li>Precio $5.302 (IVA y timbre incluido)</li>
                        <li>Agenda web, pago mediante tarjeta de débito o crédito.</li>
                        <li>Tambien podes agendarte vía telefonica al (+598) 2 600 6001 ó a través de nuestro mail
                          coordinaciones@atgen.com.uy</li>
                        
                        <li>
                          Los test a domicilio son realizan de lunes a sábado.
                        </li>
                        <li>
                          Resultado en 24 horas. Se enviará al correo
                          electrónico proporcionado.
                        </li>
                        <li>
                          Realizamos hisopados nasofaríngeos únicamente en niños
                          mayores de 1 año.
                        </li>
                      </ul>
                      <p>
                        Política de cancelación: Usted tiene derecho a revocar
                        la presente contratación, sin responsabilidad alguna,
                        debiendo para ello comunicarlo en un plazo de hasta 24
                        horas antes de la hora agendada. Vencido dicho plazo, la
                        revocación generará un costo equivalente al 100 % del
                        monto de los servicios contratados. El reintegro se
                        realizará por la misma vía por la que se efectuó el
                        pago. El tiempo que demore en acreditarse el reembolso
                        depende de cada tarjeta y/o Banco emisor que opere en
                        cada caso. Si Ud. ha modificado su fecha y hora de
                        agenda, se tomará en cuenta la fecha y hora de la
                        reserva original para determinar si se cumple con el
                        criterio de cancelación.
                      </p>
                      <div className="btn-seccion">
                        <a
                          href={AGENDA_DOMICILIO}
                          target={"_blank"}
                          rel="noreferrer"
                          className="d-flex flex-column align-items-center"
                        >
                          <img src={agendaBlanca} alt="Agenda domicilio" />
                          <div>Agendarme en domicilio</div>
                        </a>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BtnAgendaDomicilio;
